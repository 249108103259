<template>
  <div class="card vote-main">
    <div class="title">
      {{ $t("topVotes.title") }}
    </div>
    <ProgressLoader
      v-if="loadAndCalc"
      mode="indeterminate"
      color="#5AC396"
      size="150px"
    />
    <div v-else>
      <Apexchart
        :options="options"
        :series="topVoting"
        @click="goToProject"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import BigNumber from "bignumber.js";
import ProgressLoader from "@/loaders/progress-bar";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TopVotes",
  components: { ProgressLoader },
  data() {
    return {
      loadAndCalc: false,
      topVoting: []
    };
  },
  computed: {
    ...mapGetters({
      isLightTheme: "isLightTheme"
    }),

    options() {
      return {
        chart: {
          type: "bar",
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        tooltip: {
          intersect: false,
          y: {
            formatter: (v, _) => {
              return `${v}%`;
            }
          }
        },
        dataLabels: {
          formatter: (v, _) => {
            return `${v}%`;
          }
        },
        xaxis: {
          type: "category"
        },
        yaxis: {
          labels: {
            style: {
              fontFamily: "Suisse Intl",
              colors: this.isLightTheme ? "black" : "white",
              cssClass: "votes-clickable"
            }
          }
        }
      };
    }
  },
  async created() {
    await this.calcTop();
  },
  methods: {
    ...mapActions({
      getAllAuCapitalsPagination: "capitals/getAllAuCapitalsPagination"
    }),

    goToProject(event, noUse, graphData) {
      let vote = null;
      if (graphData.dataPointIndex === -1) {
        vote = _.find(item => item.x === event.target.innerHTML);
      }
      else {
        vote = this.topVoting[0]?.data[graphData.dataPointIndex];
      }
      if (vote) {
        this.$router.push({ name: "project card", params: { id: vote.id } });
      }
    },

    getPercent(project) {
      const value = BigNumber(project.currentCap);
      const oneHundredPercent = BigNumber(project.minCap != 0 ? project.minCap : project.maxCap);
      return value.multipliedBy(100).dividedBy(oneHundredPercent).toFixed(2);
    },

    async calcTop() {
      this.loadAndCalc = true;
      try {
        const resp = await this.getAllAuCapitalsPagination({
          page: 1,
          itemsPerPage: 100,
          projectType: "voting",
          status: "open"
        });
        // сортировка пока на фронте, но как будут фильтры бэка
        // все это будет переделано
        const sorted = resp.data.sort((a, b) => {
          return this.getPercent(b) - this.getPercent(a);
        });
        sorted.length = sorted.length > 10 ? 10 : sorted.length;
        this.topVoting = [{
          data: _.map(sorted, item => ({
            x: item.tokenBase.nameEng,
            y: this.getPercent(item),
            id: item.id
          })),
          name: "Value"
        }];
      }
      catch (e) {
        console.error(e);
      }
      this.loadAndCalc = false;
    }
  }
};
</script>

<style lang="scss">
.votes-clickable {
  cursor: pointer;
}
</style>

<style scoped lang="scss">

.vote-main {
  padding: 20px;

  .title {
    font-size: 24px;
  }
}
</style>