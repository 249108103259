<template>
  <button
    type="button"
    class="btn"
    :class="type"
    :style="cssVars"
    :active="active"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <div
      v-if="$slots['default']"
      :active="active"
      class="btn__text"
    >
      <slot />
    </div>
  </button>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: ""
    },
    height: {
      type: String,
      default: ""
    },
    fontSize: {
      type: Number,
      default: 14
    },
    borderRadius: {
      type: [Number, String],
      default: 22
    },
    activeBgColor: {
      type: String,
      default: ""
    },
    bgColor: {
      type: String,
      default: ""
    },
    activeTextColor: {
      type: String,
      default: ""
    },
    textColor: {
      type: String,
      default: ""
    },
    hoveredBgColor: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ""
    }
  },

  emits: ["click"],

  data() {
    return {
      basicWidth: "auto",
      basicHeight: "44px"
    };
  },

  computed: {
    ...mapGetters({
      isLightTheme: "isLightTheme"
    }),

    cssVars() {
      return {
        "--width": this.currentWidth,
        "--height": this.currentHeight,
        "--border-radius": this.borderRadius + "px",
        "--background-hovered-color": this.hoveredBgColor || this.bgDefColor,
        "--active-bg-color": this.activeBgColor,
        "--background-color": this.type == "primary" ? "#2aba7c" : this.activeBgColor,
        "--active-text-color": this.activeTextColor || this.fontColor,
        "--text-btn-color": this.textColor,
        "--font-color": this.type == "primary" ? "#FFFFFF" : this.fontColor,
        "--font-size": this.fontSize + "px"
      };
    },

    currentWidth() {
      if (this.width) {
        return this.width;
      }

      return this.basicWidth;
    },

    currentHeight() {
      if (this.height) {
        return this.height;
      }

      return this.basicHeight;
    },

    fontColor() {
      if (this.disabled) {
        return "#FFFFFF";
      }
      return this.isLightTheme ? "#080808" : "#FFFFFF";
    },

    bgDefColor() {
      return this.isLightTheme ? "#8adcba" : "#3b8d6b";
    }
  }
};
</script>

<style scoped lang="scss">
button.btn {
  border-radius: var(--border-radius);
  background-color: var(--background-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;

  width: var(--width);
  height: var(--height);

  border: 1px solid var(--color-70);

  .btn__text {
    color: var(--font-color);
    font-size: 13px;

    &[active=true] {
      color: var(--active-text-color)
    }
  }

  &[active=true] {
    border-color: var(--btn-border-color-def);
    background-color: var(--active-bg-color);
    transition: background-color 0.2s ease;
  }

  &:active{
    border-color: var(--btn-border-color-def);
    background-color: var(--background-color);
  }

  &:hover {
    background-color: var(--background-hovered-color);
    transition: background-color 0.2s ease;
    .btn__text {
      transition: color 0.2s ease;
    }
    &:active{
      border-color: var(--btn-border-color-def);
      background-color: var(--background-color);
    }
  }

  .primary {
    background-color: var(--btn-dg-color-primary);
    .btn__text {
      color: #FFFFFF;
    }
  }

  &[disabled]{
    background-color: var(--btn-dg-color-dis);
    opacity: 1;
    .btn__text {
      color: var(--btn-ft-color-dis);
    }
  }
}

</style>
