<template>
  <div class="card">
    <div class="card__header">
      <div class="fs24">
        {{ $t("transactions.label") }}
      </div>

      <div class="filters">
        <div class="filter-buttons">
          <AuSwitchButton
            :active="isAll"
            width="66px"
            border-radius="12"
            :label="$t('transactions.allPlaceholder')"
            @click="cleanFilters"
          >
            {{ $t("transactions.allPlaceholder") }}
          </AuSwitchButton>

          <AuSelect
            :value="cryptoAssetId"
            class="crypto-asset-select"
            :options="tokensOptions"
            :placeholder="$t('transactions.cryptoAssetPlaceholder')"
            @input="changeСryptoAssetId"
          />

          <Datepicker
            v-model="date"
            :locale="locale"
            auto-apply
            :max-date="new Date()"
            :enable-time-picker="false"
            :format="format"
            :placeholder="$t('transactions.datePickerPlaceholder')"
            range
            :dark="!isLightTheme"
          />
        </div>

        <AuSelect
          :value="type"
          class="type-select"
          :options="typeArray"
          :placeholder="$t('transactions.transactionType.placeholder')"
          @input="changeType"
        />
      </div>
      <div v-if="ourEth">
        <div class="card__header-line">
          <div class="card__header-date">
            <span>{{ typeTitle }} </span> <span class="card__header-month"> {{ dateTitle }} </span>
          </div>
        </div>
      </div>
    </div>

    <div v-if="ourEth">
      <div
        v-if="isDetailView"
        class="transactions-info"
      >
        <div class="transactions-info__chart">
          <AuButton icon="chevron_left_big" />

          <Apexchart
            type="donut"
            :options="options"
            :series="series"
            height="200px"
            width="200px"
          />

          <AuButton icon="chevron_right_big" />
        </div>

        <div class="transactions-info__cards">
          <TransactionCard
            v-for="(card, index) in cards"
            :key="index"
            :name="card.name"
            :value="getFormatedValue(card.value)"
            :color="card.color"
          />
        </div>
      </div>
      <div class="transactions-list">
        <div
          v-for="(group, groupIndex) in transactions"
          :key="groupIndex"
          class="transactions-group"
        >
          <div class="period">
            {{ group.date }}
          </div>

          <div class="transactions">
            <LastTransaction
              v-for="(transaction, index) in group.transactions"
              :key="index"
              class="transaction"

              :name="transaction.name"
              :transaction-type="transaction.type"
              :amount="transaction.amount"
              :is-refill="transaction.isRefill"
              :card-type="transaction.cardType"
              :decimals="transaction.decimals"
              :indent="transaction.indent"
              :mnemonic="transaction.mnemonic"
              :is-confirmed="transaction.isConfirmed"
              :hash="transaction.hash"
              :address-eth="transaction.addressEth"
              :address-from="transaction.addressFrom"
              :address-to="transaction.addressTo"
              :icon="transaction.icon"
              :project-id="transaction.projectId"
              :project-name="transaction.projectName"
              :is-voting="transaction.isVoting"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="!ourEth"
      class="h-line"
    >
      <div class="h-head-text">
        {{ $t("transactions.confirmАddress") }} <a
          href="/wallet-verification"
          class="a-link"
        >
          {{ $t("transactions.confirmАddressLink") }}
        </a>
      </div>
    </div>

    <ProgressLoader
      v-if="loading && !transactions.length"
      mode="indeterminate"
      color="#5AC396"
      size="150px"
    />

    <div
      v-if="ourEth && currentTake < totalItems"
      class="ch-footer"
    >
      <AuButton
        center
        type="primary"
        width="125px"
        :is-progress="loading"
        @click="getMore"
      >
        {{ $t("transactions.moreButton") }}
      </AuButton>
    </div>

    <div v-if="!transactions.length && !loading">
      <AuNoData />
    </div>
  </div>
</template>

<script>
import LastTransaction from "./LastTransaction.vue";
import ProgressLoader from "@/loaders/progress-bar";
import TransactionCard from "./TransactionCard.vue";


import _ from "lodash";
import Datepicker from "@vuepic/vue-datepicker";
import i18n from "@/localization/localization.js";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  components: {
    LastTransaction,
    TransactionCard,
    Datepicker,
    ProgressLoader
  },

  data() {
    return {
      isDetailView: false,
      addressEth: null,
      transactions: [],
      date: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()],
      cryptoAssetId: null,
      type: "all",
      loading: false,
      currentTake: 30,
      take: 30,
      totalItems: 0,
      typeArray: [
        { display: this.$t("transactions.transactionType.all"), value: "all" },
        { display: this.$t("transactions.transactionType.transfer"), value: "transfer" },
        { display: this.$t("transactions.transactionType.investing"), value: "investing" },
        { display: this.$t("transactions.transactionType.mint"), value: "mint" },
        { display: this.$t("transactions.transactionType.burn"), value: "burn" },
        { display: this.$t("transactions.transactionType.token_block"), value: "token_block" },
        { display: this.$t("transactions.transactionType.token_unblock"), value: "token_unblock" },
        { display: this.$t("transactions.transactionType.capital_payment"), value: "capital_payment" },
        { display: this.$t("transactions.transactionType.deposit"), value: "deposit" }]
    };
  },

  computed: {
    ...mapGetters({
      tokens: "tokens",
      arrEth: "ethAddresses",
      defaultTokenMnemonic: "defaultToken/defaultTokenMnemonic",
      contacts: "marketplace/userContact"
    }),

    isLightTheme() {
      return this.$store.getters.isLightTheme;
    },

    cards() {
      return [
        { value: 2164927, name: this.$t("transactions.cards.transfersCard"), color: "#489EE1" },
        { value: 679608.75, name: this.$t("transactions.cards.investmentsCard"), color: "#E2A64D" },
        { value: 95020, name: this.$t("transactions.cards.goodsCard"), color: "#299667" },
        { value: 679382.75, name: this.$t("transactions.cards.exchangeCard"), color: "#9720F6" }
      ];
    },

    typeTitle() {
      let ret = _.find(this.typeArray, el => el.value === this.type);
      ret = ret?.value ?? "all";
      return this.$t(`transactions.titleType.${ret}`);
    },

    sum() {
      return _.chain(this.filtered).flatMap("transactions").map(v => {
        let res = Number(v.amount) / Math.pow(10, v.decimals);
        res = v.isRefill ? res : -1 * res;
        return res;
      }).sum().value();
    },

    dateTitle() {
      let dateTitle = this.$t("transactions.titleType.allTime");
      if (this.date) {
        dateTitle = this.format(this.date);
      }
      return dateTitle;
    },

    tokensOptions() {
      return _.map(this.tokens, token => ({ display: token.nameEng, value: token.id }));
    },

    isAll() {
      return this.cryptoAssetId == null && this.date == null;
    },

    series() {
      return _.map(this.cards, "value");
    },

    options() {
      return {
        labels: _.map(this.cards, "name"),
        pie: {
          expandOnClick: false
        },
        colors: _.map(this.cards, "color"),
        dataLabels: {
          enabled: false
        },
        tooltip: {
          enabled: true,
          y: {
            formatter(value) {
              return value.toLocaleString("ru", { style: "currency", currency: "RUB" }).replace(",00", "");
            }
          }
        },
        legend: {
          show: false
        }
      };
    },

    ourEth() {
      return _.find(this.arrEth, eth => !_.isNil(eth.verifiedAt))?.value;
    },

    locale() {
      return i18n.global.locale;
    }
  },

  watch: {
    date() {
      this.getTransactions();
    }
  },

  async mounted() {
    this.cleanFilters();
    await this.getUserContact({ page: 1, itemsPerPage: 30 });
  },

  methods: {
    ...mapActions({
      getTransactionsPage: "getTransactionsPage",
      getUserContact: "marketplace/getUserContact"
    }),

    async changeType(type) {
      this.type = type;
      await this.getTransactions();
    },

    switchView() {
      this.isDetailView = !this.isDetailView;
    },

    cleanFilters() {
      this.cryptoAssetId = null;
      this.date = null;
      this.type = null;
    },

    getFormatedValue(value) {
      return value.toLocaleString("ru", { style: "currency", currency: "RUB" }).replace(",00", "");
    },

    format(date) {
      const day1 = date[0].getDate();
      const month1 = date[0].getMonth() + 1;
      const year1 = date[0].getFullYear();

      const day2 = date[1].getDate();
      const month2 = date[1].getMonth() + 1;
      const year2 = date[1].getFullYear();

      if (year1 == year2 && month1 == month2 && day1 == day2) {
        return `${moment(date[0]).locale(this.locale).format("L")}`;
      }

      if (year1 == year2 && month1 == month2 && day1 == 1 && (day2 == new Date(year2, month2, 0).getDate() || day2 == new Date().getDate())) {
        let month = moment(date[0]).locale(this.locale).format("MMMM");
        return month[0].toUpperCase() + month.slice(1) + " " + year1;
      }

      if (year1 == year2 && month1 == 1 && day1 == 1 && month2 == 12 && day2 == 31) {
        return year1;
      }

      return `${moment(date[0]).locale(this.locale).format("L")} - ${moment(date[1]).locale(this.locale).format("L")}`;
    },

    changeСryptoAssetId(value) {
      this.cryptoAssetId = value;
      this.getTransactions();
    },

    async getTransactions() {
      this.loading = true;
      if (!this.arrEth[0]?.verifiedAt) {
        this.loading = false;
        return;
      }

      let params = {
        pageId: 1,
        itemsPerPage: this.currentTake,
        adr: this.ourEth,
        auTokenId: this.cryptoAssetId,
        dateFrom: this.date ? moment(this.date[0]).set("hour", 0).set("minute", 0).set("second", 0).toISOString() : "",
        dateTo: this.date ? moment(this.date[1]).set("hour", 24).set("minute", 59).set("second", 59).toISOString() : ""
      };
      if (this.type !== "all") {
        params.methodName = this.type;
      }
      let resp = await this.getTransactionsPage(params);
      let transfer = resp.data;
      this.totalItems = resp.totalItems;
      this.transactions = [];

      if (transfer.length === 0) {
        this.loading = false;
        return;
      }
      transfer.forEach(transaction => {
        const time = moment(transaction.createdAt).format("DD.MM.YYYY");
        let records = this.transactions.find(el => el.date == time)?.transactions;
        if (records === undefined) {
          this.transactions.push({ "date": time, "sort": transaction.createdAt, "transactions": [] });
          records = this.transactions.at(-1).transactions;
        }
        this.arrEth.forEach((eth) => {
          if (_.toLower(eth.value) === _.toLower(transaction?.addressFrom)) {
            this.addressEth = transaction?.addressTo;
          }
          if (_.toLower(eth.value) === _.toLower(transaction?.addressTo)) {
            this.addressEth = transaction?.addressFrom;
          }
          if (transaction?.addressFrom == 0) {
            this.addressEth = transaction?.addressTo;
          }
        });
        records.push({
          icon: transaction?.auToken?.logoMain ?? transaction?.auToken?.mainImage?.name,
          name: _.isNil(this.addressEth) ? this.$t("transactions.unspecified") :
            this.addressEth.slice(0, 4) +
            "..." +
            this.addressEth.slice(-4),
          addressFrom: _.get(transaction, "addressFrom", "-"),
          addressTo: _.get(transaction, "addressTo", "-"),
          addressEth: this.addressEth,
          amount: _.get(transaction, "amount", null),
          isConfirmed: _.get(transaction, "isConfirmed", false),
          cardType: moment(transaction.createdAt).format("DD.MM.YYYY HH:mm:ss"),
          isRefill: _.toLower(this.ourEth) == _.toLower(transaction?.addressTo),
          type: _.get(transaction, "methodName", "transfer"),
          decimals: _.get(transaction, "auToken.decimals", 2),
          indent: _.get(transaction, "auToken.indent", 2),
          mnemonic: _.get(transaction, "auToken.mnemonicEng", "-"),
          createdAt: _.get(transaction, "createdAt", "-"),
          hash: _.get(transaction, "hash", "-"),
          projectId: _.get(transaction, "auCapital.id", null),
          projectName: _.get(transaction, "auCapital.tokenBase.nameEng", ""),
          isVoting: transaction?.auCapital?.projectType === "voting"
        });
      });

      this.transactions.sort((a, b) => {
        const diff = moment(b.sort).unix() - moment(a.sort).unix();
        if (diff > 0) {
          return 1;
        }
        if (diff < 0) {
          return -1;
        }
        return 0;
      });

      this.loading = false;
    },

    async getMore() {
      this.currentTake += this.take;
      await this.getTransactions();
    }
  }
};

</script>

<style scoped lang="scss">
.period {
  margin: 20px 0px;
  font-size: 12px;
  color: var(--color-60);
}

.crypto-asset-select {
  min-width: 220px;
}

.type-select {
  width: 172px;
}

.date-input {
  width: 175px;
}

.h-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 0px 5px 0px;
}

.h-head-action {
  font-weight: 450;
  font-size: 12px;
  text-decoration: none;
  color: var(--color-60);
}

.transactions {
  display: flex;
  flex-direction: column;
  gap: 15px;

  &-list {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  &-footer {
    padding-bottom: 10px;
  }

  &-info {
    display: flex;
    border-bottom: 1px solid var(--color-70);
    padding: 12px 0px;

    @media screen and (max-width:576px) {
      flex-direction: column;
    }

    &__chart {
      display: flex;
      align-items: center;
      margin-left: -17px;
      margin-right: 40px;

      @media screen and (max-width:576px) {
        justify-content: space-between;
      }
    }

    &__cards {
      display: flex;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;
      padding: 50px 0px;
    }
  }
}

.card {
  width: 100%;
  padding: 12px 22px 22px 20px;

  &__header {
    display: flex;
    flex-direction: column;
    gap: 20px;

    border-bottom: 1px solid var(--color-70);
    padding-bottom: 15px;

    &-date {
      font-size: 14px;
    }

    &-line {
      display: flex;
      justify-content: space-between;
    }

    &-month {
      color: #279063
    }
  }

  &__action {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-right: -15px;
  }
}

.filters {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.filter-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.a-link {
  color: var(--text-color);
  text-decoration: underline;
}
.ch-footer{
  display: flex;
  justify-content: center;
  padding: 20px;
  margin-top: 40px;
  border-top: 1px dashed var(--color-70);
}
</style>
