<template>
  <div class="is1 card">
    <div class="form-header">
      <div class="title">
        {{ `${$t('projects.form.firstStep.labelFirstPart')} ${mode} ${$t('projects.form.firstStep.labelLastPart')}` }}
      </div>
      <AuButton
        width="auto"
        bordered
        center
        @click="goBack"
      >
        {{ $t('projects.form.cancelButton') }}
      </AuButton>
    </div>
    <div class="is-label">
      {{ $t('projects.form.firstStep.projectNameLabel') }}<AuTultip :description="'The short name of your project.'" />
    </div>
    <div class="is-indent">
      <AuInput
        v-model="projectNameEng"
        :placeholder="$t('projects.form.firstStep.projectNamePlaceholder')"
        required
        clearable
      />
    </div>
    <div class="is-label">
      {{ $t('projects.form.firstStep.projectAbbrNameLabel') }}
    </div>
    <div class="is-indent">
      <AuInput
        v-model="projectAbbrNameEng"
        :placeholder="$t('projects.form.firstStep.projectAbbrNamePlaceholder')"
        maxlength="8"
        required
        clearable
      />
    </div>
    <div class="is-label">
      {{ $t('projects.form.firstStep.projectDescLabel') }}
    </div>
    <div class="is-indent">
      <AuInput
        v-model="projectDescriptionEng"
        type="TextArea"
        required
        clearable
        :placeholder="$t('projects.form.firstStep.projectDescEngPlaceholder')"
      />
    </div>
    <div class="is-label">
      {{ $t('projects.form.firstStep.projectTokensCountLabel') }}
    </div>
    <div class="is-indent">
      <AuInput
        v-model="tokensCount"
        required
        :placeholder="$t('projects.form.firstStep.projectTokensCountPlaceholder')"
        type="Mask"
        :mask="Number"
      />
    </div>
    <div class="is-label">
      {{ $t('projects.form.firstStep.projectCompanyLabel') }}
    </div>
    <div class="is-indent">
      <AuSelect
        :value="company"
        :options="companiesOptions"
        :placeholder="$t('projects.form.firstStep.projectCompanyPlaceholder')"
        required
        clearable
        @input="value => company = value"
        @clearValue="company = null"
      />
    </div>
    <div class="is-label">
      {{ $t('projects.form.firstStep.projectType') }}
    </div>
    <div class="is-indent">
      <AuSelect
        :value="projectType"
        :options="projectTypeOptions"
        required
        clearable
        :placeholder="$t('projects.form.firstStep.projectTypePlaceholder')"
        @input="value => projectType = value"
      />
    </div>
    <div class="is-label">
      {{ $t('projects.form.firstStep.projectCryptoTypeLabel') }}
    </div>
    <div class="is-indent">
      <AuSelect
        :value="cryptoActiveType"
        :options="cryptoActiveTypeOptions"
        required
        clearable
        disabled
        :placeholder="$t('projects.form.firstStep.projectCryptoTypePlaceholder')"
        @input="changeCryptoActiveType"
      />
    </div>
    <div
      v-if="showInvestType"
      class="is-label"
    >
      {{ $t('projects.form.firstStep.projectInvestTypeLabel') }}
    </div>
    <div
      v-if="showInvestType"
      class="is-indent"
    >
      <AuSelect
        :value="investType"
        :options="investmentTypeOptions"
        required
        clearable
        disabled
        :placeholder="$t('projects.form.firstStep.projectInvestTypePlaceholder')"
        @input="value => investType = value"
      />
    </div>
    <div
      v-if="showIsVoiting"
      class="is-label"
    >
      {{ $t('projects.form.firstStep.projectVoitingTypeLabel') }}
    </div>
    <div
      v-if="showIsVoiting"
      class="is-indent"
    >
      <AuSelect
        :value="isVoiting"
        :options="isVoitingOptions"
        required
        clearable
        :placeholder="$t('projects.form.firstStep.projectVoitingTypePlaceholder')"
        @input="value => isVoiting = value"
      />
    </div>

    <AuButton
      width="100%"
      type="primary"
      center
      @click="nextStep"
    >
      {{ $t('projects.form.nextButton') }}
    </AuButton>

    <div
      v-if="showError"
      class="error"
    >
      {{ $t('projects.form.error') }}
    </div>
  </div>
</template>

<script>
import _ from "lodash";

import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    id: {
      type: String,
      default: "new"
    },
    firstPartOfProjectInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      projectNameEng: null,
      projectAbbrNameEng: null,
      projectDescriptionRus: null,
      projectDescriptionEng: null,
      tokensCount: "...",
      company: null,
      projectType: "invest",
      cryptoActiveType: "investment",
      investType: "non-redeemable",
      isVoiting: false,
      showError: false,
      projectTypeOptions: [
        {
          display: this.$t("projects.projectTypes.voting"),
          value: "voting"
        },
        {
          display: this.$t("projects.projectTypes.invest"),
          value: "invest"
        }
      ],
      cryptoActiveTypeOptions: [
        {
          display: this.$t("projects.cryptoActiveTypes.investment"),
          value: "investment"
        },
        {
          display: this.$t("projects.cryptoActiveTypes.access"),
          value: "access"
        },
        {
          display: this.$t("projects.cryptoActiveTypes.ticket"),
          value: "ticket"
        },
        {
          display: this.$t("projects.cryptoActiveTypes.voucher"),
          value: "voucher"
        },
        {
          display: this.$t("projects.cryptoActiveTypes.management"),
          value: "management"
        }
      ],
      investmentTypeOptions: [
        {
          display: this.$t("projects.investType.redeemable"),
          value: "redeemable"
        },
        {
          display: this.$t("projects.investType.non-redeemable"),
          value: "non-redeemable"
        }
      ]
    };
  },

  computed: {
    ...mapGetters({
      language: "language",
      companies: "companies",
      requestsToIssue: "requestsToIssue"
    }),

    showIsVoiting() {
      return this.investType == "non-redeemable";
    },

    showInvestType() {
      return this.cryptoActiveType == "investment";
    },

    companiesOptions() {
      let verifiedCompany = this.companies.filter((item) => {
        return item.isVerified == true;
      });
      return _.map(verifiedCompany, (company) => ({
        display: this.language == "ru" ? company.nameRus : company.nameEng,
        value: `/api/companies/${company.id}`
      }));
    },

    mode() {
      return this.id === "new" ?
        this.$t("projects.form.firstStep.labelNew") :
        this.$t("projects.form.firstStep.labelEdit");
    },

    isVoitingOptions() {
      return [
        {
          display: this.$t("projects.form.firstStep.yes"),
          value: true
        },
        {
          display: this.$t("projects.form.firstStep.no"),
          value: false
        }
      ];
    }
  },

  async mounted() {
    if (this.firstPartOfProjectInfo) {
      this.projectNameEng = this.firstPartOfProjectInfo.projectNameEng;
      this.projectAbbrNameEng = this.firstPartOfProjectInfo.projectAbbrNameEng;
      this.projectDescriptionRus = this.firstPartOfProjectInfo.projectDescriptionEng;
      this.projectDescriptionEng = this.firstPartOfProjectInfo.projectDescriptionEng;
      this.tokensCount = this.firstPartOfProjectInfo.tokensCount;
      this.company = this.firstPartOfProjectInfo.company;
      this.cryptoActiveType = this.firstPartOfProjectInfo.cryptoActiveType;
      this.projectType = this.firstPartOfProjectInfo.projectType;
      if (this.firstPartOfProjectInfo.investType) {
        this.investType = this.firstPartOfProjectInfo.investType;
      }
      if (this.showIsVoiting) {
        this.isVoiting = this.firstPartOfProjectInfo.isVoiting;
      }
    }

    if (_.isEmpty(this.requestsToIssue)) {
      await this.getRequestsToIssue();
    }
    if (_.isEmpty(this.companies)) {
      await this.getCompanies();
    }
    if (this.id !== "new" && !this.firstPartOfProjectInfo) {
      this.setFields(this.requestsToIssue.find(item => item.id == this.id));
    }
  },

  methods: {
    ...mapActions({
      getRequestsToIssue: "getRequestsToIssue",
      getCompanies: "getCompanies"
    }),

    nextStep() {
      let firstPartOfProjectInfo = {
        projectNameEng: this.projectNameEng,
        projectAbbrNameEng: this.projectAbbrNameEng,
        projectDescriptionRus: this.projectDescriptionEng,
        projectDescriptionEng: this.projectDescriptionEng,
        tokensCount: this.tokensCount,
        company: this.company,
        projectType: this.projectType,
        cryptoActiveType: this.cryptoActiveType
      };
      if (this.showInvestType) {
        firstPartOfProjectInfo.investType = this.investType;
      }
      if (this.showIsVoiting) {
        firstPartOfProjectInfo.isVoiting = this.isVoiting;
      }

      if (Object.values(firstPartOfProjectInfo).length != _.filter(Object.values(firstPartOfProjectInfo), value => value !== null).length) {
        this.showError = true;
      }
      else {
        this.$emit("firstPartOfProject", firstPartOfProjectInfo);
        this.$emit("nextStep");
      }
    },

    changeCryptoActiveType(value) {
      this.cryptoActiveType = value;
      this.investType = null;
      this.isVoiting = null;
    },

    goBack() {
      this.$router.push({ name: "my projects" });
    },

    setFields(data) {
      if (!data) {
        return;
      }

      this.projectNameEng = data.tokenNameEng;
      this.projectAbbrNameEng = data.tokenMnemonicEng;
      this.projectDescriptionRus = data.descriptionCapitalEng;
      this.projectDescriptionEng = data.descriptionCapitalEng;
      this.tokensCount = data.tokenCount;
      this.company = data.company;
      this.projectType = data.projectType;
      this.cryptoActiveType = data.cryptoActiveType;
      if (this.showInvestType) {
        this.investType = data.investType;
      }
      if (this.showIsVoiting) {
        this.isVoiting = data.isVoiting;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.form-header {
  display: flex;
  justify-content: space-between;
  .title {
    font-weight: 500;
    font-size: 20px;
  }
}
.error {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  color: red;
}

.is1{
  font-family: 'Suisse Intl',serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  padding: 20px;
}
.is-label{
  margin-bottom: 6px;
}
.is-line{
  width: 50%;
}
.is-indent{
  margin-bottom: 24px;
  gap: 10px;
}
.is-small-indent{
  margin-bottom: 15px;
}
.is-text-area{
  width: 100%;
  height: 150px;
  border-radius: 14px;
  padding-top: 12px;
  resize: none;
}
.is-next{
  border: 1px dashed #289566;
  border-radius: 14px;
  padding: 12px;
  &:hover {
    cursor: pointer;
  }
}


</style>