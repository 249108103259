<template>
  <ProgressLoader
    v-if="isLoad"
    mode="indeterminate"
    color="#5AC396"
    size="150px"
  />
  <div
    v-else
    class="ss-elements"
  >
    <div>
      <div class="ss-binance">
        {{ $t("buyForm.second.important") }}
      </div>
      <div class="ss-message">
        {{ $t("buyForm.second.binanceOnly") }}
      </div>
      <div class="ss-message">
        {{ $t("buyForm.second.tokenInfo") }}
      </div>
    </div>
    <div class="ss-adr">
      <div
        class="ss-copy"
      >
        <span>{{ address }}</span>
        <SuccessMark
          v-show="copied"
          :show-animate="copied"
        />
        <img
          v-show="!copied"
          class="ss-icon"
          src="/svg/buyForm/copy.svg"
          alt=""
          @click="copyAddress"
        >
      </div>
    </div>
    <AuButton
      center
      width="40%"
      type="primary"
      :is-progress="isNotify"
      @click="nextStage"
    >
      {{ $t("buyForm.second.next") }}
    </AuButton>
    <div
      class="ss-wallet"
      @click="actionWallet"
    />
  </div>
</template>

<script>
import BigNumber from "bignumber.js";
import ProgressLoader from "@/loaders/progress-bar";
import SuccessMark from "@/components/SuccessMark.vue";
import Web3 from "web3";
import { mapActions, mapGetters } from "vuex";
import { useWeb3Modal, useWeb3ModalProvider } from "@web3modal/ethers5/vue";

export default {
  name: "SecondStage",
  components: {
    ProgressLoader, SuccessMark
  },
  props: {
    count: {
      type: Number,
      default: 0
    },
    price: {
      type: Number,
      default: 0.08
    }
  },
  data() {
    return {
      address: null,
      payId: null,
      isLoad: true,
      copied: false,
      isError: false,
      isNotify: false,
      walletStage: 1,
      provider: {},
      balance: {},
      account: {}
    };
  },
  computed: {
    ...mapGetters({
      ethAddresses: "ethAddresses",
      defaultTokenId: "defaultToken/defaultTokenId",
      defaultTokenMnemonic: "defaultToken/defaultTokenMnemonic",
      defaultTokenEnv: "defaultToken/defaultTokenEnv"
    }),

    sum() {
      return BigNumber(this.count).multipliedBy(this.price).toFixed(2);
    },

    addressFrom() {
      return (Array.isArray(this.ethAddresses) ?
        this.ethAddresses.at(0)?.value : this.ethAddresses) ?? "-";
    },

    button() {
      let ret = this.$t("buyForm.second.walletConnect");
      ret = this.walletStage > 1 ? this.$t("buyForm.second.send") : ret;
      ret = this.walletStage === 3 && !this.balance.isZero() ?
        this.$t("buyForm.second.send") + this.balance.toFixed(2) + this.defaultTokenEnv : ret;
      return ret;
    },

    status() {
      return this.isError ? this.$t("buyForm.second.attention") + this.sum + this.defaultTokenEnv :
        this.$t("buyForm.second.walletRequest");
    }
  },
  async mounted() {
    await this.getAddress();
  },
  methods: {
    ...mapActions({
      payCreate: "payCreate",
      payNotify: "payNotify"
    }),

    async getAddress() {
      const params = {
        value: "10",
        payValue: "10.00",
        auToken: `/api/au_tokens/${this.defaultTokenId}`,
        email: this.$auth.remember(),
        addressFrom: this.addressFrom
      };
      try {
        const resp = await this.payCreate(params);
        this.address = resp.address;
        this.payId = resp.payId;
        this.$emit("setAddress", this.address);
      }
      catch (e) {
        this.address = this.$t("buyForm.second.adrError");
      }
      this.isLoad = false;
    },

    async nextStage() {
      this.isNotify = true;
      await this.payNotify(this.payId);
      this.isNotify = false;
      this.$emit("next");
    },

    copyAddress() {
      navigator.clipboard.writeText(this.address).then(() => {
        this.copied = true;
        setTimeout(() => this.copied = false, 1700);
      });
    },

    showError(message) {
      this.$toast.error(message);
    },

    async actionWallet() {
      switch (this.walletStage) {
        case 1:
          {
            const { open } = useWeb3Modal();
            await open();
            this.$watch("provider", async newValue => {
              if (newValue) {
                await this.initWallet();
                this.walletStage++;
              }
            });
            const resp = useWeb3ModalProvider();
            this.provider = resp.walletProvider;
          }
          break;
        case 2:
          await this.getBalance();
          if (this.balance.gte(this.sum)) {
            await this.send(this.sum);
          }
          else {
            this.isError = true;
            this.walletStage++;
          }
          break;
        case 3:
          if (!this.balance.isZero()) {
            await this.send(this.balance.toString(10));
          }
          break;
      }
    },

    async initWallet() {
      try {
        this.account = await this.provider.request({ method: "eth_requestAccounts" });
      }
      catch (e) {
        this.showError(this.$t("buyForm.second.errorConnect"));
        return;
      }

      this.account = this.account.at(0) ?? 0;
      this.provider.on("accountsChanged", () => this.account = this.account.at(0) ?? 0);

      const bsc = {
        chainId: "0x38",
        chainName: "BNB Smart Chain Mainnet",
        rpcUrls: ["https://bsc-dataseed.binance.org/"],
        blockExplorerUrls: ["https://bscscan.com/"],
        nativeCurrency: {
          symbol: "BNB",
          decimals: 18
        }
      };
      const currentId = await this.provider.request({ method: "eth_chainId" });
      if (currentId === bsc.chainId) {
        return;
      }

      let needAdded = false;
      try {
        await this.provider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: bsc.chainId }]
        });
      }
      catch (e) {
        if (e.code === 4902) {
          needAdded = true;
        }
        else {
          this.showError(this.$t("buyForm.second.errorChange"));
          return;
        }
      }

      if (needAdded) {
        try {
          await this.provider.request({
            method: "wallet_addEthereumChain",
            params: [bsc] });
        }
        catch (e) {
          this.showError(this.$t("buyForm.second.errorAdd"));
        }
      }
    },

    async getBalance() {
      const abi = [{
        constant: true,
        inputs: [
          {
            name: "_owner",
            type: "address"
          }
        ],
        name: "balanceOf",
        outputs: [
          {
            name: "balance",
            type: "uint256"
          }
        ],
        payable: false,
        type: "function"
      }];
      const web3 = new Web3(this.provider);
      const contract = new web3.eth.Contract(abi, "0x55d398326f99059fF775485246999027B3197955");
      let data = await contract.methods.balanceOf(this.account).call();
      this.balance = BigNumber(data).dividedBy(BigNumber(10).pow(18));
    },

    async send(sendSum) {
      let abi = [
        {
          constant: false,
          inputs: [
            {
              internalType: "address",
              name: "recipient",
              type: "address"
            },
            {
              internalType: "uint256",
              name: "amount",
              type: "uint256"
            }
          ],
          name: "transfer",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool"
            }
          ],
          payable: false,
          stateMutability: "nonpayable",
          type: "function"
        }
      ];

      const web3 = new Web3(this.provider);

      const contract = new web3.eth.Contract(abi, "0x55d398326f99059fF775485246999027B3197955", { from: this.account });
      const amount = BigNumber(sendSum).multipliedBy(BigNumber(10).pow(18));

      const data = contract.methods.transfer(this.address, amount.toString(10)).encodeABI();
      // console.log(data);

      try {
        await this.provider.request({
          method: "eth_sendTransaction",
          params: [{
            from: this.account,
            to: "0x55d398326f99059fF775485246999027B3197955",
            value: 0,
            data: data
            // gasLimit: "0x9c40",
            // maxPriorityFeePerGas: "0x0",
            // maxFeePerGas: "0xb2d05e00"
          }]
        });
      }
      catch (e) {
        this.showError(this.$t("buyForm.second.errorTransaction"));
      }
    }
  }
};
</script>

<style lang="scss">
.ss-elements button.btn .btn__text {
  @media screen and (max-width:600px) {
    font-size: 14px !important;
  }

  @media screen and (max-width:450px) {
    font-size: 11px !important;
  }
}
</style>

<style scoped lang="scss">
.ss-elements {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ss-title {
  font-size: 24px;
  text-align: center;
}
.ss-binance {
  margin-top: 20px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;

  @media screen and (max-width:600px) {
    font-size: 15px;
  }
}
.ss-adr {
  background-color: #A3E2C6;
  border-radius: 15px;
  padding: 30px 20px;
  margin: 35px 0;

  @media screen and (max-width:600px) {
    padding: 15px 5px;
    margin: 25px 0;
  }
}
.ss-copy {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;

  @media screen and (max-width:600px) {
    font-size: 15px;
  }

  @media screen and (max-width:450px) {
    font-size: 11px;
  }
}
.ss-icon {
  cursor: pointer;
}
.ss-error {
  font-size: 24px;
  margin-top: 20px;
  &[red=true] {
    color: red;
  }
}
.ss-wallet {
  width: 40%;
  height: 10px;
}
.ss-message {
  font-size: 20px;
  font-weight: 600;
  text-align: center;

  @media screen and (max-width:600px) {
    font-size: 15px;
  }
}
</style>