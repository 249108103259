import auth from "./auth";
import store from "./store/index.js";
import { io } from "socket.io-client";

let socket = null;
if (process.env.VUE_APP_WEBSOCKET_ENABLED == "true") {
  socket = io(process.env.VUE_APP_WEBSOCKET_HOST);

  // Проверка состояний
  socket.on("connect", () => {
    store.commit("socket/setSocket", socket);
    store.commit("socket/setSocketStatus", socket.connected);
  });

  socket.on("disconnect", () => {
    store.commit("socket/setSocket", null);
    store.commit("socket/setSocketStatus", false);
  });

  socket.on("connect_error", () => {
    store.commit("socket/setSocket", null);
    store.commit("socket/setSocketStatus", false);
  });

  const MessageType = {
    entityChanged: "entityChanged"
  };

  const EntityType = {
    user: "user",
    au_capital: "au_capital"
  };

  // Получение данных от сервера.
  socket.on(MessageType.entityChanged, (data) => {
    const defaultTokenId = store.getters["defaultToken/defaultTokenId"];

    switch (data.entityType) {
      case EntityType.user: {
        if (auth.check() && auth.user().id == data.entityId) {
          store.dispatch("profile/getProfileWihtoutLoading", data.entityId);
          store.commit("profile/setCheckProfile", Date.now());
          if (defaultTokenId) {
            store.dispatch("getBalances", defaultTokenId);
          }
        }
        break;
      }
      case EntityType.au_capital: {
        store.dispatch("capitals/getAuCapitals", data.entityId);
        break;
      }
    }
  });
}
else {
  console.warn("VUE_APP_WEBSOCKET_HOST not set, web-sockets disabled");
  store.commit("socket/setSocketStatus", false);
}
export default socket;