import _ from "lodash";
import BigNumber from "bignumber.js";
import moment from "moment";

const getSectionName = (value, sectionCapital) => {
  const id = getId(value);
  const res = sectionCapital.find((item) => item.id == id);
  if (res === undefined) {
    return null;
  }
  return res.name;
};

const getId = (value) => {
  if (value === null || value === undefined) {
    return null;
  }

  const slashPos = value.lastIndexOf("/") + 1;

  if (slashPos === 0) {
    return null;
  }

  return value.slice(slashPos);
};

export const convertCapital = (item, sectionCapital) => {
  let project = {};
  project.id = item.id;
  project.tag = getSectionName(item.section, sectionCapital);
  project.sectionId = getId(item.section);
  project.name = item.tokenBase.nameEng;
  project.icon = item?.thumb;
  project.dateStart = moment(item.dateStart).format("DD.MM.YYYY  HH:mm");
  project.dateEnd = moment(item.dateEnd).format("DD.MM.YYYY  HH:mm");
  project.dateClose = item.dateClose ? moment(item.dateClose).format("DD.MM.YYYY  HH:mm") : "~";
  project.rawStart = item.dateStart;
  project.rawEnd = item.dateEnd;
  project.profitability = _.get(item, "expectedProfit", "-");
  project.maxCap = item.maxCap;
  let percent = BigNumber(item.currentCap);
  percent = percent.multipliedBy(100);
  percent = percent.dividedBy(item.maxCap);
  percent = percent.toNumber().toFixed(1);
  project.progressProcent = Number(percent);
  project.isSuccess = item.isSuccess;
  project.isClosed = item.isClosed;
  project.decimals = item.tokenBase.decimals;
  project.mnemonicDecimals = item.tokenQuote.decimals;
  project.mnemonicIndent = item.tokenQuote?.indent;
  project.mnemonicEng = item.tokenQuote.mnemonicEng;
  project.tokenQuoteId = item.tokenQuote.id;
  project.quote = item?.quote ?? "-";
  project.priceBase = item.priceBase;
  project.minCap = item.minCap;
  project.descriptionEng = item.descriptionEng;
  project.descriptionRus = item.descriptionRus;
  project.currentCap = item.currentCap;
  project.end = item.dateEnd;
  project.countryCode = item.company?.countryCode;
  project.company = item.company?.nameEng;
  project.files = item.additionalFile;
  project.listedAt = moment(item.listedAt).format("DD.MM.YYYY");
  project.sum = item.sum;
  project.visibility = item?.visibility;
  project.allowedForUsers = item.allowedForUsers;
  project.minCount = item?.minCount ?? "-";
  project.projectType = item?.projectType;
  setStatus(project, item);
  return project;
};

const setStatus = (project, item) => {
  if (moment(item.dateStart).isAfter(new Date)) {
    project.status = "Waiting";
    project.statusColor = "#C0C0C0";
    project.statusIcon = "clock";
  }
  else if (project.isClosed) {
    if (item.isSuccess) {
      project.status = "Completed";
      project.statusColor = "#C0C0C0";
      project.statusIcon = "rocketc";
    }
    else {
      project.status = "Declined";
      project.statusColor = "#1C1C1C";
      project.statusIcon = "closed";
    }
  }
  else if (moment(new Date).isAfter(item.dateEnd)) {
    project.status = "Closed";
    project.statusColor = "#C0C0C0";
    project.statusIcon = "rocketc";
  }
  else {
    project.status = "Open";
    project.statusColor = "#2aba7c";
    project.statusIcon = "rocketc";
  }
};