<template>
  <div
    class="no_data"
  >
    {{ $t('briefcase.ordersNoData') }}
  </div>
</template>

<script>
export default {
  name: "AuNoData"
};
</script>

<style scoped lang="scss">
.no_data {
  padding-top: 24px;
  display: flex;
  justify-content: center;
  color: var(--color-50);
}
</style>

