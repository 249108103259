<template>
  <div class="card vote-history-main">
    <div class="title">
      {{ $t("voteHistory.title") }}
    </div>
    <ProgressLoader
      v-if="loading"
      mode="indeterminate"
      color="#5AC396"
      size="150px"
    />
    <div v-else>
      <Apexchart
        :options="options"
        :series="voting"
      />
      <AuPagination
        v-if="totalItems > itemsPerPage"
        :hide="loading || !voting.length"
        :total-items="totalItems"
        @pageChanged="pageChanged"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import BigNumber from "bignumber.js";
import ProgressLoader from "@/loaders/progress-bar";
import { mapActions } from "vuex";

export default {
  name: "VotingHistory",
  components: { ProgressLoader },
  data() {
    return {
      options: {
        chart: {
          type: "bar",
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        xaxis: {
          type: "category"
        }
      },
      loading: false,
      page: 1,
      itemsPerPage: 30,
      totalItems: 0,
      voting: []
    };
  },
  async created() {
    await this.getVoting();
  },
  methods: {
    ...mapActions({
      getAllAuCapitalsPagination: "capitals/getAllAuCapitalsPagination"
    }),

    async pageChanged(actualPage) {
      this.page = actualPage;
      await this.getVoting();
    },

    getY(project) {
      return BigNumber(project.currentCap)
        .dividedBy(BigNumber(10).pow(project.tokenQuote.decimals)).toFixed(2);
    },

    async getVoting() {
      this.loading = true;
      try {
        const resp = await this.getAllAuCapitalsPagination({
          page: this.page,
          itemsPerPage: this.itemsPerPage,
          projectType: "voting"
        });
        this.voting = [{
          data: _.map(resp.data, item => ({ x: item.tokenBase.nameEng, y: this.getY(item) }))
        }];
        this.totalItems = resp.totalItems;
      }
      catch (e) {
        console.error(e);
      }
      this.loading = false;
    }
  }
};
</script>

<style scoped lang="scss">
.vote-history-main {
  padding: 20px;

  .title {
    font-size: 24px;
  }
}
</style>