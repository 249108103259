<template>
  <div
    class="p_card"
    :class="isTester ? '' : 'tester'"
    :isInvestment="isInvestment"
  >
    <div class="card-doby">
      <RouterLink
        v-if="!isInvestment"
        :to="`/${routePath}/${project.id}`"
        class="link-part"
      >
        <div
          class="icons-wrap"
          :style="style"
        >
          <div
            v-if="status"
            class="time"
            :vote="vote === 'Vote'"
          >
            <AuIcon :icon="icon" /> {{ status }}
          </div>
          <div
            v-if="vote === 'Vote'"
            class="vote"
          >
            <AuIcon icon="vote" /> Voting
          </div>
          <div class="tag">
            {{ project.tag }}
          </div>
        </div>

        <div class="no-image-wrap">
          <div
            class="no-image"
          >
            <img
              v-if="project.icon"
              class="image"
              :src="project.icon"
              alt="ico"
            >

            <img
              v-else
              src="/img/project-card/no-photo.svg"
              :alt="project.name"
            >
          </div>
        </div>
      </RouterLink>

      <div class="name-wrap">
        <span class="name">{{ project.name }}</span>

        <AuIcon
          v-if="!isOuter"
          :icon="rateIcon"
          @click="starClick"
        />
      </div>

      <div
        v-if="!isInvestment"
        class="description"
        v-html="description"
      />

      <div v-if="!isInvestment">
        <div class="left_text">
          {{ $t("Price") }}
        </div>
        <div class="price">
          <AuDecimalStatic
            :value="project.priceBase"
            :addition="project.mnemonicEng"
            :decimal="project.mnemonicDecimals"
            smart-indent
          />
        </div>
      </div>
      <div v-else>
        <div class="left_text">
          {{ $t("auCapitals.capitalCard.sum") }}
        </div>
        <div class="price">
          <AuDecimalStatic
            :value="project.sum"
            :decimal="project.mnemonicDecimals"
            :indent="project.mnemonicIndent"
            :addition="project.mnemonicEng"
            smart-indent
          />
        </div>
      </div>

      <div class="line-wrap">
        <div class="line">
          <div class="left_text">
            {{ $t(`auCapitals.capitalCard.hardCap${vote}`) }}
          </div>
          <div class="right_text">
            <AuDecimalStatic
              :value="project.maxCap"
              :decimal="project.mnemonicDecimals"
              :indent="project.mnemonicDecimals"
              :addition="project.mnemonicEng"
              smart-indent
            />
          </div>
        </div>
        <div class="line indent_bottom">
          <div class="left_text">
            {{ $t("auCapitals.capitalCard.posting") }}
          </div>
          <div
            :class="{'green-text': timer && timer[0] === 'O'}"
            class="right_text"
          >
            {{ timer && timer[0] === 'O' ? timer : project.dateStart }}
          </div>
        </div>
        <div class="line">
          <div class="left_text">
            {{ project.dateClose === "~" ?
              $t("auCapitals.capitalCard.completion") : $t("auCapitals.capitalCard.repayment") }}
          </div>
          <div class="right_text">
            {{ timer && timer[0] === 'C' ? timer : (project.dateClose === "~" ? project.dateEnd : project.dateClose) }}
          </div>
        </div>
      </div>

      <div class="progress_line">
        <div class="progress_text">
          <AuDecimalStatic
            :value="project.currentCap"
            :addition="project.mnemonicEng"
            :decimal="project.mnemonicDecimals"
            :indent="project.mnemonicIndent"
            smart-indent
          />
        </div>
        <div class="progress_text">
          {{ project.progressProcent + "%" }}
        </div>
      </div>

      <ProgressBar
        class="indent_progress"
        :procent="project.progressProcent"
        :status="status"
      />

      <div class="indent_progress-button">
        <RouterLink
          :to="{name: routeName, params: { id: project.id }}"
          class="menu-items"
        >
          <AuButton
            :type="status == 'Waiting' || status == 'Open' ? 'primary' : ''"
            width="100%"
            center
          >
            <div>
              {{ $t("auCapitals.capitalCard.more") }}
            </div>
          </AuButton>
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script>
import BigNumber from "bignumber.js";
import ProgressBar from "@/components/progressBar/ProgressBar";
import { mapActions, mapGetters } from "vuex";

import _ from "lodash";
import moment from "moment";

export default {
  name: "CapitalCard",

  components: {
    ProgressBar
  },

  props: {
    project: {
      type: Object,
      default: null
    },
    isInvestment: {
      type: Boolean,
      default: false
    },
    routeName: {
      type: String,
      default: "project card"
    },
    routePath: {
      type: String,
      default: "project-card"
    }
  },

  data() {
    return {
      tokenCount: "0",
      timerStatus: null,
      timerIcon: null,
      timerColor: null,
      timer: null
    };
  },

  computed: {
    ...mapGetters({
      auOwners: "auOwners",
      language: "language",
      followings: "capitals/followings"
    }),

    isTester() {
      return this.project?.visibility;
    },

    isOuter() {
      return this.$route.meta.outer == true;
    },

    description() {
      if (this.language == "en") {
        return this.project.descriptionEng ? this.project.descriptionEng.replace("<p>", "").replace("</p>", "") : "";
      }
      else {
        return this.project.descriptionRus ? this.project.descriptionRus.replace("<p>", "").replace("</p>", "") : "";
      }
    },

    style() {
      return {
        "--status-color": this.color
      };
    },

    progressStyle() {
      return this.project.id % 2 !== 0;
    },

    owner() {
      return this.auOwners.find(item => item.auToken.id == this.project.tokenQuoteId);
    },

    isEnough() {
      let count = BigNumber(this.tokenCount);
      count = count.multipliedBy(this.project.priceBase);
      if (this.owner === undefined) {
        return false;
      }
      return count.isLessThanOrEqualTo(this.owner.amountFree);
    },

    isCorrectNumber() {
      return this.tokenCount.match(/^\d+$/);
    },

    following() {
      return _.find(this.followings, { auCapital: "/api/au_capitals/" + this.project.id });
    },

    rateIcon() {
      return this.following ? "star-following" : "star";
    },

    status() {
      return this.timerStatus ?? this.project.status;
    },

    icon() {
      return this.timerIcon ?? this.project.statusIcon;
    },

    color() {
      return this.timerColor ?? this.project.statusColor;
    },

    vote() {
      return this.project.projectType === "voting" ? "Vote" : "";
    }
  },

  mounted() {
    this.startTimer();
  },

  methods: {
    ...mapActions({
      getAuCapitals: "capitals/getAuCapitals",
      sendTokenEvents: "sendTokenEvents",
      unsetFollowing: "capitals/unsetFollowing",
      setFollowing: "capitals/setFollowing",
      getFollowings: "capitals/getFollowings"
    }),

    async starClick() {
      if (this.following) {
        await this.unsetFollowing(this.project.id);
      }
      else {
        await this.setFollowing(this.project.id);
      }

      await this.getFollowings();
    },

    startTimer() {
      const waiting = moment().isBefore(this.project.rawStart) ? {
        moment: moment(this.project.rawStart),
        status: "Open",
        icon: "rocketc",
        color: "#2aba7c",
        text: "Opens in"
      } :
        (moment().isBefore(this.project.rawEnd) ? {
          moment: moment(this.project.rawEnd),
          status: "Closed",
          icon: "rocketc",
          color: "#C0C0C0",
          text: "Closes in"
        } :
          null);

      if (waiting) {
        const diff = waiting.moment.diff(moment(), "seconds");
        let ticker = diff < (24 * 60 * 60) && diff > 0 ? moment.duration(diff, "seconds") : null;
        this.timer = ticker ? this.timer = this.formatTimer(waiting.text, ticker) : null;

        const intervalHandle = setInterval(() => {
          if (moment().isAfter(waiting.moment)) {
            this.timerStatus = waiting.status;
            this.timerIcon = waiting.icon;
            this.timerColor = waiting.color;
            this.timer = null;
            ticker = null;
            clearInterval(intervalHandle);
            if (waiting.status === "Open") {
              this.startTimer();
            }
          }

          if (ticker) {
            ticker = ticker.subtract(1, "seconds");
            this.timer = this.formatTimer(waiting.text, ticker);
          }
        }, 1000);
      }
    },

    formatTimer(text, duration) {
      let time = duration.hours();
      let epilogue = "hours";
      if (time == 0) {
        time = duration.minutes();
        epilogue = "minutes";
        if (time == 0) {
          time = duration.seconds();
          epilogue = "seconds";
        }
      }
      return text + ` ${time} ${epilogue}`;
    }
  }
};
</script>

<style scoped lang="scss">
.p_card{
  border-radius: 25px;
  width: calc(25% - 19px);
  padding: 15px;
  position: relative;
  background-color: var(--card-color);

  @media screen and (max-width:1400px) {
    width: calc(33% - 14px);
  }

  @media screen and (max-width:900px) {
    width: calc(50% - 20px);
  }

  @media screen and (max-width:600px) {
    width: 100%;
  }

  &[isInvestment=true] {
    .name-wrap {
      margin: 2px 0 4px;
    }
  }

  .card-doby {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .link-part {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: -15px;
      padding: 15px;
    }
  }
}
.tester {
  background: var(--tester-color);
}

.line{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 7px;
}

.progress_line {
  margin-top: 30px;
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
}

.line-wrap {
  margin-top: 20px;
}

.icons-wrap {
  z-index: 1;
}

.time
{
  background: var(--status-color);
  border-radius: 10px;
  padding: 10px;
  color: #FFFFFF;
  font-weight: 450;
  font-size: 12px;
  position: absolute;
  top: 25px;
  left: 25px;
  display: flex;
  align-items: center;
  gap: 8px;
  &[vote="true"] {
    justify-content: center;
    min-width: 90px;
    height: 38px;
  }
}

.vote
{
  background: #D29C1E;
  border-radius: 10px;
  padding: 10px;
  color: #FFFFFF;
  font-weight: 450;
  font-size: 12px;
  position: absolute;
  top: 70px;
  left: 25px;
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 90px;
  height: 38px;
}

.tag
{
  border: 1px solid #FFFFFF;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 9px;
  color: black;
  font-weight: 450;
  font-size: 12px;
  position: absolute;
  top: 25px;
  right: 23px;
}

.name-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 20px;

  .name {
    color: var(--text-color);
    font-weight: 450;
    font-size: 18px;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    -moz-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-clamp: 1;
    box-orient: vertical;
  }

  img {
    cursor: pointer;

    &:hover {
      scale: 1.2;
    }

    &:active {
      scale: 1.1;
    }
  }
}

.description {
  color: var(--color-40);
  height: 60px;
  margin: 5px 0px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-clamp: 3;
  box-orient: vertical;
  font-size: 14px;
}

.date {
  margin-top: 10px;
  margin-bottom: 20px;

  display: flex;
  gap: 10px;

  font-size: 12px;

  &__label {
    color: var(--color-30)
  }
}

.price
{
  font-weight: 500;
  font-size: 20px;
  color: #28E593;
  margin-top: -5px;
}

.left_text
{
  font-weight: 450;
  font-size: 14px;
  color: var(--color-50);
}

.progress_text {
  font-weight: 450;
  font-size: 12px;
  color: var(--color-20);
}

.right_text{
  font-weight: 450;
  font-size: 14px;
  text-align: end;
  color: var(--text-color);
}

.image
{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
}

.indent_progress-button
{
  margin-top: 15px;

  &-text {
    color: var(--color-20)
  }
}
.modal_button
 {
   display: flex;
   justify-content: end;
   margin-top: 15px;
 }
.modal_button_margin
{
  margin-right: 10px;
}
.modal_header{
  margin-bottom: 10px;
  border-bottom: 1px solid var(--color-70);
}
.modal_text_small
{
  font-size: 11px;
}
.modal_text_large{
  font-size: 13px;
}
.modal_color{
  color: var(--color-60);
}
.modal_text_error{
  color: red;
  font-size: 10px;
}
.no-image-wrap {
  position: relative;
  width: 100%;

  &:before{
    content: "";
    display: block;
    padding-top: 50%;
  }

  .no-image {
    position:  absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-10);
    border-radius: 15px;
  };
}
.green-text {
  color: #30BC89;
  font-weight: 600;
}
</style>
