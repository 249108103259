<template>
  <div class="card">
    <div class="card__header">
      <div class="fs15">
        {{ $t("dashboard.transactions.label") }}
      </div>

      <RouterLink
        to="/transactions"
        class="card__action"
      >
        {{ $t("dashboard.transactions.goTo") }}
        <AuIcon icon="chevron_right" />
      </RouterLink>
    </div>

    <div v-if="!loading && ourEth">
      <div class="period">
        {{ lastDay }}
      </div>
      <div
        v-if="transactions.length"
        class="transactions"
      >
        <LastTransaction
          v-for="(transaction, index) in transactions"
          :key="index"
          class="transaction"

          :name="transaction.name"
          :hash="transaction.hash"
          :address-eth="transaction.addressEth"
          :address-from="transaction.addressFrom"
          :address-to="transaction.addressTo"
          :transaction-type="transaction.type"
          :amount="transaction.amount"
          :is-refill="transaction.isRefill"
          :card-type="transaction.cardType"
          :decimals="transaction.decimals"
          :mnemonic="transaction.mnemonic"
          :is-confirmed="transaction.isConfirmed"
          :icon="transaction.icon"
          :project-id="transaction.projectId"
          :project-name="transaction.projectName"
          :is-voting="transaction.isVoting"
        />
      </div>
    </div>

    <ProgressLoader
      v-if="loading && ourEth"
      mode="indeterminate"
      color="#5AC396"
      size="150px"
    />

    <div
      v-if="!ourEth"
      class="h-line"
    >
      <div class="h-head-text">
        {{ $t("transactions.confirmАddress") }} <a
          href="/security"
          class="a-link"
        >
          {{ $t("transactions.confirmАddressLink") }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import LastTransaction from "./LastTransaction.vue";
import moment from "moment";
import ProgressLoader from "@/loaders/progress-bar";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ProgressLoader,
    LastTransaction
  },

  data() {
    return {
      loading: true,
      transactions: [],
      addressEth: null,
      totalItems: 1
    };
  },

  computed: {
    ...mapGetters({
      arrEth: "ethAddresses",
      contacts: "marketplace/userContact"
    }),

    ourEth() {
      return _.find(this.arrEth, eth => !_.isNil(eth.verifiedAt))?.value;
    },

    lastDay() {
      if (!this.transactions.length) {
        return this.$t("dashboard.transactions.notTransaction");
      }
      else {
        if (this.transactions.length && moment(this.transactions[0]?.createdAt).format("DD.MM.YYYY") == moment().format("DD.MM.YYYY")) {
          return this.$t("dashboard.transactions.toDay");
        }
        else if (
          moment(this.transactions[0]?.createdAt).format("DD.MM.YYYY") == moment().subtract(1, "days").format("DD.MM.YYYY")
        ) {
          return this.$t("dashboard.transactions.yesterDay");
        }
        else {
          return "";
        }
      }
    }
  },

  async mounted() {
    await this.getTransactions();
  },

  methods: {
    ...mapActions({
      getTransactionsPage: "getTransactionsPage",
      getUserContact: "marketplace/getUserContact"
    }),

    async getTransactions() {
      const resp = await this.getTransactionsPage({ pageId: 1, sort: "desc", adr: this.ourEth });
      const allData = resp?.data;
      this.totalItems = resp?.totalItems;
      if (!allData) {
        return;
      }

      allData.forEach((transaction) => {
        if (_.isUndefined(transaction.auToken)) {
          return;
        }

        this.arrEth.forEach((eth) => {
          if ((eth.value).toLowerCase() === (transaction.addressFrom).toLowerCase()) {
            this.addressEth = transaction.addressTo;
          }
          if ((eth.value).toLowerCase() === (transaction.addressTo).toLowerCase()) {
            this.addressEth = transaction.addressFrom;
          }
          if (transaction.addressFrom == 0) {
            this.addressEth = transaction.addressTo;
          }
        });
        this.transactions.push({
          icon: transaction?.auToken?.logoMain ?? transaction?.auToken?.mainImage?.name,
          name: this?.addressEth ? this.addressEth.slice(0, 4) + "..." + this.addressEth.slice(-4) : "",
          addressFrom: transaction.addressFrom,
          addressTo: transaction.addressTo,
          addressEth: this.addressEth,
          amount: transaction.amount,
          isConfirmed: transaction.isConfirmed,
          cardType: moment(transaction.createdAt).format("DD.MM.YYYY HH:mm:ss"),
          isRefill: this.arrEth[0]?.value.toLowerCase() == transaction?.addressTo.toLowerCase(),
          type: transaction?.methodName,
          decimals: transaction?.auToken?.decimals,
          mnemonic: transaction?.auToken?.mnemonicEng,
          createdAt: transaction.createdAt,
          hash: transaction.hash,
          projectId: _.get(transaction, "auCapital.id", null),
          projectName: _.get(transaction, "auCapital.tokenBase.nameEng", ""),
          isVoting: transaction?.auCapital?.projectType === "voting"
        });
      });
      this.transactions.sort((a, b) => {
        const diff = moment(b.createdAt).unix() - moment(a.createdAt).unix();
        if (diff > 0) {
          return 1;
        }
        if (diff < 0) {
          return -1;
        }
        return 0;
      });
      if (this.transactions?.length > 3) {
        this.transactions.length = 3;
      }
      this.loading = false;
    }
  }
};
</script>

<style scoped lang="scss">
.period {
  margin: 14px 0px;
  font-size: 12px;
}

.transactions {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.indention {
  margin-top: 50px;
}

.card {
  width: 100%;
  padding: 15px 22px 22px 22px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--text-color);

    border-bottom: 1px solid var(--color-70);
    padding-bottom: 11px;
  }

  &__action {
    font-size: 11px;
    color: var(--color-50);
    text-decoration: none;
  }
}
.h-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-color);
  padding: 25px 0px 5px 0px;
}
.a-link {
  color: var(--text-color);
  text-decoration: underline;
}
</style>
