<template>
  <div
    v-if="stage && balance"
    class="exchange-rates"
  >
    <ExchangeRate
      v-for="(rate, index) in rates"
      :id="rate.id"
      :key="index"
      :name="rate.name"
      :values="rate.values"
      :icon="rate.icon"
      :color="rate.color"
      :link="rate.link"
      :decimals="rate.decimals"
      :mnemonic="rate.mnemonic"
      :value-default="rate.valueDefault"
      :decimal-default="rate.decimalDefault"
      :mnemonic-default="rate.mnemonicDefault"
      :info="rate.info"
    />
  </div>
</template>

<script>
import _ from "lodash";

import CurrencySupport from "@/help/currencySupport";
import ExchangeRate from "./ExchangeRate";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ExchangeRates",

  components: {
    ExchangeRate
  },

  data() {
    return {
      rates: [],
      currencySup: new CurrencySupport()
    };
  },

  computed: {
    ...mapGetters({
      auOwners: "auOwners",
      ethAddresses: "ethAddresses",
      tokensUtility: "tokensUtility",
      tokensDao: "tokensDao",
      tokens: "tokens",
      defaultTokenMnemonic: "defaultToken/defaultTokenMnemonic",
      balances: "balances"
    }),

    stage() {
      if (!this.address) {
        return false;
      }
      else if (!this.address.verifiedAt) {
        return false;
      }
      else if (this.address.status == "add") {
        return false;
      }
      else if (this.address.status !== "added") {
        return false;
      }
      return true;
    },

    address() {
      return _.get(this.ethAddresses, "[0]", "");
    },

    balance() {
      const totalSum = _.sumBy(this.rates, "valueDefault");
      if (totalSum > 0) {
        return true;
      }
      else {
        return false;
      }
    },

    defaultDecimal() {
      return this.tokens.find(token => token.mnemonicEng == this.defaultTokenMnemonic)?.decimals;
    }
  },

  watch: {
    defaultTokenMnemonic: {
      immediate: true,
      handler() {
        this.checkBalance();
      }
    },
    balances: {
      immediate: true,
      handler() {
        this.checkBalance();
      }
    }
  },

  methods: {
    ...mapActions({
      getNfts: "goods/getCurrentUserNfts"
    }),
    checkBalance() {
      this.rates = [],
      this.tokensUtility.forEach(item => {
        const course = this.currencySup.getBalance(item.id);

        if (item.type != "asset") {
          this.rates.push({
            id: item.id,
            name: item.nameEng,
            values: [course, course, course, course],
            icon: item.logoMain,
            color: "289566",
            link: "token info",
            decimals: item.decimals,
            mnemonic: item.mnemonicEng,
            valueDefault: this.currencySup.getBalance(item.id) * this.currencySup.getCurrency(item.id, this.defaultTokenMnemonic),
            decimalDefault: item.decimals,
            mnemonicDefault: this.defaultTokenMnemonic
          });
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.exchange-rates {
  display: flex;
  justify-content: start;
  gap: 20px;

  @media screen and (max-width: 640px)  {
    flex-wrap: wrap;
  }

  @media screen and (max-width: 450px)  {
    flex-direction: column;
    gap: 10px;
  }
}
</style>