<template>
  <div>
    <AuBreadcrumb />
    <div class="card buy-panel">
      <div
        v-if="stage==1"
        class="buy-header"
      >
        {{ $t("buyForm.title") }}
      </div>
      <div v-if="arrEth[0]?.verifiedAt && arrEth[0]?.status">
        <SecondStage
          v-if="stage===1"
          :count="count"
          :price="price"
          @next="stage++"
          @setAddress="value => address = value"
        />
        <ThirdStage
          v-if="stage===2"
          :address="address"
          @next="toMainPage"
        />
      </div>
      <div v-else>
        <div>
          {{ $t("buyForm.notVerify.description") }}
        </div>

        <RouterLink
          to="/wallet-verification"
        >
          <AuButton
            class="mt-2"
            type="primary"
            center
            width="200px"
          >
            <div>
              {{ $t("buyForm.notVerify.btnVerify") }}
            </div>
          </AuButton>
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script>
import SecondStage from "@/components/buyForm/SecondStage";
import ThirdStage from "@/components/buyForm/ThirdStage";
import { mapGetters } from "vuex";
export default {
  name: "BuyForm",
  components: { ThirdStage, SecondStage },
  data() {
    return {
      stage: 1,
      count: 0,
      price: 1,
      address: ""
    };
  },
  computed: {
    ...mapGetters({
      defaultTokenMnemonic: "defaultToken/defaultTokenMnemonic",
      arrEth: "ethAddresses"
    })
  },
  methods: {
    getCount(arg) {
      this.stage++;
      this.count = arg;
    },

    toMainPage() {
      this.$router.push({ name: "base" });
    }
  }
};
</script>

<style scoped>
.buy-panel {
  padding: 20px;
}
.buy-header {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 35px;
}
</style>