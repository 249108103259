<template>
  <div>
    <div class="card p-4">
      <div class="fs24">
        {{ $t("Smart indent") }}
      </div>

      <AuTabView resizable>
        <AuTabPanel :title="$t('Test')">
          <AuTable
            class="briefcase-table briefcase-table-hiddable"
            :columns="columnsTable"
            :items="itemsTable"
          >
            <template #result="{ value }">
              <AuDecimalStatic
                :value="value.value"
                :decimal="value.decimals"
                :indent="value.indent"
                :show-addition="false"
              />
            </template>
          </AuTable>
        </AuTabPanel>
      </AuTabView>
    </div>
    <div class="card p-4 mt-4">
      <div class="fs24">
        {{ $t("TestPage") }}
      </div>
      <hr>
      <div class="mt-2 mb-2">
        default, disable false
      </div>
      <div class="d-flex">
        <AuSwitchButton
          class="dist"
          width="66px"
          border-radius="8"
        >
          {{ $t("All") }}
        </AuSwitchButton>
        <AuSwitchButton
          class="dist"
          width="66px"
          :font-size="14"
        >
          {{ $t("dashboard.rates.buttons.all") }}
        </AuSwitchButton>
        <AuButton
          class="dist"
          center
          @click="getMore"
        >
          {{ $t("Download more") }}
        </AuButton>
      </div>


      <hr>
      <div class="mt-2 mb-2">
        primary, disable false
      </div>
      <div class="d-flex">
        <AuSwitchButton
          type="primary"
          class="dist"
          width="66px"
          border-radius="8"
        >
          {{ $t("All") }}
        </AuSwitchButton>
        <AuSwitchButton
          type="primary"
          class="dist"
          width="66px"
          :font-size="14"
        >
          {{ $t("dashboard.rates.buttons.all") }}
        </AuSwitchButton>
        <AuButton
          type="primary"
          class="dist"
          center
          @click="getMore"
        >
          {{ $t("Download more") }}
        </AuButton>
      </div>

      <hr>
      <div class="mt-2 mb-2">
        disable true
      </div>
      <div class="d-flex">
        <AuSwitchButton
          disabled
          class="dist"
          width="66px"
          border-radius="8"
        >
          {{ $t("All") }}
        </AuSwitchButton>
        <AuSwitchButton
          disabled="true"
          class="dist"
          width="66px"
          :font-size="14"
        >
          {{ $t("dashboard.rates.buttons.all") }}
        </AuSwitchButton>
        <AuButton
          disabled
          class="dist"
          center
          @click="getMore"
        >
          {{ $t("Download more") }}
        </AuButton>
      </div>


      <ProgressLoader
        v-if="loading"
        mode="indeterminate"
        color="#5AC396"
        size="150px"
      />

      <AuPagination
        :total-items="totalItems"
      />
    </div>
    <div class="card p-4 mt-4 down-block">
      <div class="fs24">
        {{ $t("TestPage") }}
      </div>
      <hr>
      <div class="mt-2 mb-2">
        default, disable false
      </div>
      <div class="d-flex">
        <AuSwitchButton
          class="dist"
          width="66px"
          border-radius="8"
        >
          {{ $t("All") }}
        </AuSwitchButton>
        <AuSwitchButton
          class="dist"
          width="66px"
          :font-size="14"
        >
          {{ $t("dashboard.rates.buttons.all") }}
        </AuSwitchButton>
        <AuButton
          class="dist"
          center
          @click="getMore"
        >
          {{ $t("Download more") }}
        </AuButton>
      </div>

      <hr>
      <div class="mt-2 mb-2">
        primary, disable false
      </div>
      <div class="d-flex">
        <AuSwitchButton
          type="primary"
          class="dist"
          width="66px"
          border-radius="8"
        >
          {{ $t("All") }}
        </AuSwitchButton>
        <AuSwitchButton
          type="primary"
          class="dist"
          @click="getMore"
        >
          {{ $t("dashboard.rates.buttons.all") }}
        </AuSwitchButton>
        <AuButton
          type="primary"
          class="dist"
          center
          @click="getMore"
        >
          {{ $t("Download more") }}
        </AuButton>
      </div>

      <hr>
      <div class="mt-2 mb-2">
        disable true
      </div>
      <div class="d-flex">
        <AuSwitchButton
          disabled
          class="dist"
          width="66px"
          border-radius="8"
        >
          {{ $t("All") }}
        </AuSwitchButton>
        <AuSwitchButton
          disabled="true"
          class="dist"
          width="66px"
          :font-size="14"
        >
          {{ $t("dashboard.rates.buttons.all") }}
        </AuSwitchButton>
        <AuButton
          disabled
          class="dist"
          center
          @click="getMore"
        >
          {{ $t("Download more") }}
        </AuButton>
      </div>


      <ProgressLoader
        v-if="loading"
        mode="indeterminate"
        color="#5AC396"
        size="150px"
      />

      <AuPagination
        :total-items="totalItems"
      />
    </div>
    <div class="d-flex flex-row gap-3 p-4 mt-4">
      <CapitalCardLoader
        v-for="item in [1,2,3,4]"
        :key="item"
        :is-investment="true"
        height="338px"
      />
    </div>
  </div>
</template>

<script>
import CapitalCardLoader from "@/components/capital/CapitalCardLoader";
import ProgressLoader from "@/loaders/progress-bar";

export default {
  name: "TestPage",
  components: {
    ProgressLoader,
    CapitalCardLoader
  },
  data() {
    return {
      page: 3,
      totalItems: 100,
      loading: false
    };
  },

  computed: {
    isLightTheme() {
      return this.$store.getters.isLightTheme;
    },

    typeTitle() {
      return this.$t("transactions.titleType.spending");
    },

    columnsTable() {
      return [
        { name: "data received", align: "start" },
        { name: "decimals", property: "decimals" },
        { name: "indent", property: "indent" },
        { name: "correct result", property: "correct" },
        { name: "result", align: "center", slot: "result", property: "result", decimal: true }
      ];
    },

    itemsTable() {
      return [
        {
          dataReceived: "30000000000000000",
          decimals: 18,
          indent: 2,
          correct: "0.03",
          resultOne: {
            value: "30000000000000000",
            decimals: 18,
            indent: 2
          }
        },

        {
          dataReceived: "1000000.0000",
          decimals: "-",
          indent: "-",
          correct: "1 000 000",
          resultOne: {
            value: "1000000.0000"
            // decimals: 0
            // indent: 0
          }
        },
        {
          dataReceived: "1000000.1234",
          decimals: "-",
          indent: "-",
          correct: "1 000 000.1234",
          resultOne: {
            value: "1000000.1234",
            // decimals: 0
            indent: 4
          }
        },
        {
          dataReceived: "1000000.120000",
          decimals: "-",
          indent: "-",
          correct: "1 000 000.12",
          resultOne: {
            value: "1000000.120000"
            // decimals: 0
            // indent: 2
          }
        },
        {
          dataReceived: "1000000.10000",
          decimals: "-",
          indent: "-",
          correct: "1 000 000.1",
          resultOne: {
            value: "1000000.10000"
            // decimals: 0
            // indent: 2
          }
        },
        {
          dataReceived: "1000000.10000",
          decimals: "-",
          indent: 2,
          correct: "1 000 000.10",
          resultOne: {
            value: "1000000.10000",
            // decimals: 0
            indent: 2
          }
        },
        {
          dataReceived: "123456789",
          decimals: 3,
          indent: "-",
          correct: "123 456 789",
          resultOne: {
            value: "123456789",
            decimals: 3
            // indent: 0
          }
        },
        {
          dataReceived: "123456789",
          decimals: 3,
          indent: 3,
          correct: "123 456.789",
          resultOne: {
            value: "123456789",
            decimals: 3,
            indent: 3
          }
        },
        {
          dataReceived: "12345678902",
          decimals: 2,
          indent: 2,
          correct: "123 456 789.02",
          resultOne: {
            value: "12345678902",
            decimals: 2,
            indent: 2
          }
        },
        {
          dataReceived: "1234567892000",
          decimals: 4,
          indent: 4,
          correct: "123 456 789.2000",
          resultOne: {
            value: "1234567892000",
            decimals: 4,
            indent: 4
          }
        },
        {
          dataReceived: "1234567892001",
          decimals: 4,
          indent: "-",
          correct: "1234567892001",
          resultOne: {
            value: "1234567892001",
            decimals: 4
            // indent: 4
          }
        },
        {
          dataReceived: "1234567892001",
          decimals: 4,
          indent: 2,
          correct: "123456789.20",
          resultOne: {
            value: "1234567892001",
            decimals: 4,
            indent: 2
          }
        }
      ];
    }
  },

  methods: {
    getMore() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    }
  }

};
</script>

<style scoped lang="scss">
.dist{
  margin-right: 4px;
}
.down-block {
  background: #00000000;
  border: 1px solid var(--color-70)
}
</style>
