<template>
  <span v-if="language==='en'">
    <p>Voting Agreement</p>
    This Voting Agreement ("Agreement") comes into effect on [Effective Date].
    This Agreement applies to all actions and interactions related to the voting process conducted on the platform operated by Autentic LTD and/or its affiliates.
    Autentic LTD ("Autentic LTD", hereinafter "we" or "us"), registered under the laws of the Seychelles with the address Suite 1, Second Floor, Sound & Vision House, Francis Rachel Str., Victoria, Mahe, Seychelles, organizes the voting process on its platform and provides services, including but not limited to the websites https://aut.finance and https://autentic.capital, as well as the investor's cabinet accessible via https://cabinet.autentic.capital/ (collectively referred to as the "Services").
    <p>1. Terms and Definitions</p>
    1.1 "Platform": a set of websites, services, and tools provided by Autentic LTD for organizing voting, including the investor’s cabinet accessible via https://cabinet.autentic.capital/.
    1.2 "User": an individual or legal entity registered on the Platform, holding AUT tokens, and eligible to participate in voting.
    1.3 "AUT Token": a digital asset representing the token of the project https://aut.finance/, with the token contract address on the BSC network 0x9e14C36896Daf970AE77A03E157e2dd3d0577c5b, which entitles the holder to voting rights on the Platform.
    1.4 "Project": an initiative presented on the Platform for voting, which Users can vote for.
    1.5 "Voting": the process where Users allocate their AUT tokens among the projects presented on the Platform.
    1.6 "Agreement": this Voting Agreement, regulating the terms and procedures of participation in voting on the Platform.
    1.7 "Partners": a legal entity presenting projects for voting on the Platform and responsible for their subsequent tokenization, including but not limited to real estate projects.
    <p>2. Purpose of Voting on the Platform</p>
    The voting on the Platform is designed to provide each AUT token holder with the opportunity to select projects that align with their personal investment interests, as well as to participate in the selection of promising projects that can benefit both individual investors (clients of the Platform) and the community of AUT token holders as a whole.
    <p>3. User Registration on the Platform</p>
    3.1. To use the voting functionality on the Platform, the User must complete the registration procedure by providing complete and accurate information and agreeing to the terms of this Agreement.
    3.2. The User is responsible for ensuring the accuracy and timeliness of the provided information and bears responsibility for its truthfulness.
    <p>4. Deposit of AUT Tokens on the Platform</p>
    4.1. To participate in voting, the User is required to deposit AUT tokens into their account on the Platform.
    4.2. The User agrees that only AUT token holders are eligible to vote on the Platform, and AUT tokens are the exclusive medium for casting votes on a project.
    4.3. The voting section for projects on the Platform becomes available to all Users after registration, and participation in voting is possible once they have deposited AUT tokens into their account.
    4.4. The User agrees and confirms that they have the necessary knowledge and skills to work with AUT tokens, including the ability to transfer and manage them.
    4.5. The Company is not responsible for AUT tokens deposited on the Platform by methods not provided for or supported by the Platform.
    <p>5. Projects for Voting</p>
    5.1. Projects presented for voting on the Platform are introduced and selected by partners.
    5.2. In the future, these projects may be tokenized on the Autentic Platform.
    5.3. The Company disclaims all liability for the accuracy of the data and materials provided by partners.
    5.4. All materials and information about the projects are presented "as is," without any express or implied warranties.
    5.5. The User agrees that all decisions made based on the information presented in the projects are the sole responsibility of the User.
    5.6. The User is not authorized to use the materials provided by partners and posted in the voting section for personal purposes, transfer them to third parties, or use them in social networks, other public platforms, or media without prior notice to the Company.
    5.7. The Company is not liable for any losses incurred as a result of using the provided materials and recommends that the User independently verify and evaluate the accuracy and relevance of the information before making investment decisions.
    <p>6. Catalog of Projects for Voting</p>
    6.1. Registered and authorized Users can access the catalog of projects open for voting.
    6.2. Each project in the catalog includes a detailed description, the specified voting period, and other key characteristics necessary for decision-making.
    <p>7. Voting Procedure</p>
    7.1. The User has the right to vote for projects submitted for voting using their AUT tokens.
    7.2. One AUT token equals one vote. The User may allocate their voting tokens across multiple projects or vote for a single project.
    7.3. Tokens used in voting are locked for the duration of the voting process.
    7.4. During this period, bonus AUT tokens will be credited to the User's account. The bonus is calculated at the end of the voting period at an annual rate of 150%, based on the actual duration of voting for the project. For example, 150% per year means that the User would receive 150% of the locked tokens over the full year.
    7.5. After the voting ends, the AUT tokens used for voting will be returned to the User's account along with the bonus AUT tokens.
    <p>8. Voting Terms and Conditions</p>
    8.1. For each project in the catalog, either a voting deadline or a required quorum of votes is set, after which further voting on the project becomes impossible.
    8.2. The voting period is determined individually for each project and is specified in the project's description.
    8.3. Once the voting deadline is reached or the required quorum is achieved, the voting process ends.
    <p>9. Processing of Winning Projects</p>
    9.1. After voting concludes and results are calculated, projects that receive the most votes and are deemed winners will be offered to partners for tokenization.
    9.2. Autentic LTD is not responsible for the tokenization process of winning projects, including the terms and timing of tokenization, which will be determined by the project’s partners.
    9.3. Information about the tokenization of winning projects and subsequent actions will be provided to Users on the Platform and, where appropriate, through additional communication channels.
    <p>10. Confidentiality and Data Protection</p>
    10.1. The Platform ensures the protection of Users' personal data in accordance with applicable law.
    10.2. All voting results are public and available for viewing by any User of the Platform. However, information about who voted and how they voted remains confidential and is not disclosed.
    10.3. The Platform implements appropriate technical and organizational measures to safeguard data from unauthorized access, modification, disclosure, or destruction.
    10.4. The User agrees that their participation in voting may be used to optimize the functionality of the Platform, but in an anonymized form.
    <p>11. Force Majeure</p>
    11.1. The Company is not responsible for delays or failures to fulfill its obligations under this Agreement if caused by force majeure events, including but not limited to natural disasters, fires, floods, wars, military actions, terrorist acts, strikes, blockades, actions of government authorities, or other circumstances beyond the reasonable control of the Company.
    11.2. In the event of such circumstances, the Company will notify Users of the occurrence of force majeure and will make every effort to mitigate the adverse effects.
    <p>12. Amendments to the Agreement</p>
    12.1. The Company reserves the right to unilaterally amend this Agreement.
    12.2. In the event of amendments to the Agreement, the Company will notify Users via the Platform’s designated communication channels, including but not limited to email, notifications in the User’s account on the Platform, or other means of communication. The notification will be sent 14 calendar days prior to the changes taking effect.
    12.3. Continued use of the Platform after the effective date of such amendments shall constitute the User's acceptance of the revised Agreement. Users are required to periodically review the Agreement for potential changes.
    <p>13. Disclaimer of Liability</p>
    13.1. The User agrees that the results of voting may not reflect their personal preferences or expectations.
    13.2. The Company notifies the User that it is not liable for the results of the voting, and the User accepts this condition by agreeing to this Agreement.
    13.3. The User hereby waives any and all claims related to the results of the voting against the Company, its administration, and all affiliated entities.
    <p>14. Withdrawal from this Agreement</p>
    14.1. The User may terminate this Agreement at any time at their discretion. To do so, the User must notify the Company by sending a written notice to the official email address office@autentic.capital. The termination of the Agreement will take effect 14 calendar days following the Company’s receipt of the notification.
    14.2. From the moment the termination takes effect, the User loses the ability to participate in any current or future votes on the Platform and to receive rewards related to the voting process. All ongoing votes in which the User is participating will be canceled, and the tokens used for voting will be returned to the User's account.
    14.3. After termination of the Agreement, the Platform retains the User's voting history and all related data in accordance with the privacy policy.
    14.4. The User has the right to re-enter into the Voting Agreement by sending a written notification to the Company’s official email address office@autentic.capital.
    14.5. The Company reserves the right to terminate this Agreement with respect to a specific User in case of violation of the Agreement's terms, provision of false information, or for other reasons. In such a case, the Company will notify the User of the termination by sending a corresponding message by any means deemed appropriate by the Company.
    <p>15. Final Provisions</p>
    15.1. This Agreement may be amended by the Platform administration, and Users will be notified through the appropriate communication channels.
    15.2. In case of disputes or disagreements related to the performance or interpretation of this Agreement, the parties agree to make every reasonable effort to resolve them through negotiations.
    15.3. If the dispute cannot be resolved through negotiations within 5 calendar days from its occurrence, the parties may refer to mediation. The parties are free to choose a mediator themselves and propose a mediator to the other party for approval. If the mediator proposed by the other party is rejected for any reason twice, the parties agree to consider the third mediator final.
    15.4. The decision made by the mediator is final and binding on both parties.
    15.5. The Platform is accessible at https://cabinet.autentic.capital/, where Users can access the investor's cabinet and the project voting section after registering on the Platform and depositing AUT tokens.
    15.6. Users can contact customer support via email at support@autentic.capital for assistance with questions related to the use of the Platform, including technical issues, difficulties with registration, integration of AUT tokens, voting, or access to the personal account. The Company aims to respond to User inquiries within two business days from receipt. If more time is required to resolve the issue, the User will be notified of the status of their inquiry and the expected resolution time.
  </span>
  <span v-else>
    <p>Соглашение о Голосовании</p>
    Настоящее Соглашение о голосовании ("Соглашение") вступает в силу с [Дата вступления в силу].
    Настоящее Соглашение применяется ко всем действиям и взаимодействиям, связанным с процессом голосования, проводимым на платформе, управляемой компанией Autentic LTD и/или её аффилированными лицами.
    Компания Autentic LTD ("Autentic LTD", далее "мы" или "нас"), зарегистрированная в соответствии с законодательством Сейшельских Островов и имеющая адрес Suite 1, Second Floor, Sound & Vision House, Francis Rachel Str., Victoria, Mahe, Seychelles, организует процесс голосования на своей платформе и предоставляет услуги, включая, но не ограничиваясь, веб-сайтами https://aut.finance и https://autentic.capital и кабинета инвестора https://cabinet.autentic.capital/ (совместно именуемые "Услуги").
    <p>1. Термины и Определения</p>
    1.1 "Платформа": совокупность веб-сайтов, сервисов и инструментов, предоставляемых Autentic LTD для организации голосования, включая кабинет инвестора, доступный по адресу https://cabinet.autentic.capital/.
    1.2 "Пользователь": физическое или юридическое лицо, зарегистрированное на Платформе, обладающее токенами AUT и имеющее право участвовать в голосовании.
    1.3 "Токен "AUT": цифровой актив представляющий собой токен проекта https://aut.finance/, адрес контракта токена в сети BSC 0x9e14C36896Daf970AE77A03E157e2dd3d0577c5b, и который предоставляет право на участие в голосовании.
    1.4 "Проект": инициатива, представленная на Платформе для голосования, за которую Пользователи могут голосовать.
    1.5 "Голосование": процесс, в ходе которого Пользователи распределяют свои токены AUT среди проектов, представленных на Платформе.
    1.6 "Соглашение": настоящее Соглашение о голосовании, регулирующее условия и порядок участия в голосовании на Платформе.
    1.7 "Партнёры": юридическое лицо, которое представляет проекты для голосования на Платформе и отвечает за их последующую токенизацию, включая, но не ограничиваясь, проектами в сфере недвижимости.
    <p>2. Цель Голосования на Платформе</p>
    Голосование на Платформе создано с целью предоставления каждому держателю токена AUT возможности выбирать проекты, которые отвечают их личным инвестиционным интересам, а также участвовать в отборе перспективных проектов, способных принести пользу как индивидуальным инвесторам - клиентам платформы, так и сообществу держателей токена AUT в целом.
    <p>3. Регистрация Пользователей на Платформе</p>
    3.1. Для использования функционала голосования на Платформе Пользователю необходимо пройти процедуру регистрации, указав достоверные данные и согласившись с условиями настоящего Соглашения.
    3.2. Пользователь обязуется поддерживать актуальность предоставленной информации и несет ответственность за её достоверность.
    <p>4. Депозит Токена AUT на Платформу</p>
    4.1. Для участия в голосовании Пользователю необходимо завести токен AUT на свой аккаунт на Платформу.
    4.2. Пользователь выражает согласие с тем, что голосовать на Платформе могут только держатели токена AUT и токен AUT является единственным средством, посредством которого можно выразить свой голос за проект.
    4.3. Раздел голосования за проекты на Платформе открывается всем Пользователям после регистрации, участие в голосовании становится доступным после того как они заведут токен AUT на свою учётную запись.
    4.4. Пользователь соглашается и подтверждает, что обладает необходимыми знаниями и навыками для работы с токенами AUT, включая умение их переводить и управлять ими.
    4.5. Компания не несет ответственности за токены AUT, которые были заведены на Платформу способами, не предусмотренными и не поддерживаемыми Платформой.
    <p>5. Проекты для Голосования</p>
    5.1. Проекты, представленные для голосования на Платформе, представлены и отобраны партнёрами.
    5.2. В будущем эти проекты могут быть токенизированы на Платформе Autentic.
    5.3. Компания не несёт ответственности за достоверность данных и материалов, предоставленных партнёрами.
    5.4. Все материалы и информация о проектах представлены "как есть", без каких-либо явных или подразумеваемых гарантий.
    5.5. Пользователь соглашается с тем, что все решения, принятые на основании информации, представленной в проектах, являются личной ответственностью Пользователя.
    5.6. Пользователь не имеет права использовать материалы, предоставленные партнёрами и размещённые в разделе для голосования, без предварительного уведомления Компании, в своих целях, передавать их третьим лицам или использовать их в социальных сетях, иных публичных платформах или СМИ.
    5.7. Компания не несёт ответственности за любые убытки, возникшие в результате использования предоставленных материалов, и рекомендует Пользователю самостоятельно проверять и оценивать достоверность и актуальность информации перед принятием инвестиционных решений.
    <p>6. Каталог Проектов для Голосования</p>
    6.1. Зарегистрированные и авторизованные Пользователи могут получить доступ к каталогу проектов, выставленных на голосование.
    6.2. Каждый проект в каталоге имеет своё описание, сроки голосования, а также другие ключевые характеристики, необходимые для принятия решения.
    <p>7. Процедура Голосования</p>
    7.1. Пользователь имеет право голосовать за проекты, выставленные на голосование, используя свои токены AUT.
    7.2. Один токен AUT равен одному голосу. Пользователь может распределить свои токены между несколькими проектами или отдать все токены за один проект.
    7.3. Токены, используемые в голосовании, блокируются на срок проведения голосования.
    7.4. В течение этого периода на заблокированные токены начисляются бонусные токены AUT. Бонус начисляется по итогу периода голосования из расчета 150% годовых и зависит от фактического срока голосования по проекту. Например: 150% годовых означает, что за полный год Пользователь получил бы 150% от заблокированных токенов.
    7.5. После завершения голосования, использованные при голосовании токены AUT будут возвращены на аккаунт Пользователя вместе с бонусными токенами AUT.
    <p>8. Сроки и Условия Голосования</p>
    8.1. Для каждого проекта в каталоге установлено либо предельное время для голосования, либо количество голосов (кворум), необходимых для определения успешности голосования, по истечении которого дальнейшее голосование за проект становится невозможным.
    8.2. Время для голосования устанавливается индивидуально для каждого проекта и указывается в описании проекта.
    8.3. После исчисления предельного времени голосования, либо по достижении необходимого кворума, процесс голосования прекращается.
    <p>9. Обработка Проектов Победителей</p>
    9.1. По завершении голосования и подведении итогов, проекты, которые получили наибольшее количество голосов и признаны победителями, будут предложены партнёрам для токенизации.
    9.2. Компания Autentic LTD не несет ответственности за процесс токенизации победивших проектов, включая условия и сроки токенизации, которые будут определяться партнёрами проекта.
    9.3. Информация о токенизации победивших проектов и дальнейших действиях будет предоставлена пользователям на Платформе, а также по мере необходимости через другие средства связи.
    <p>10. Конфиденциальность и Защита Данных</p>
    10.1. Платформа обеспечивает защиту персональных данных Пользователей в соответствии с применимым законодательством.
    10.2. Все результаты голосования являются публичными и доступны для просмотра любым Пользователем Платформы. Однако информация о том, кто и как голосовал, остаётся конфиденциальной и не разглашается.
    10.3. Платформа использует необходимые технические и организационные меры для защиты данных от несанкционированного доступа, изменения, раскрытия или уничтожения.
    10.4. Пользователь соглашается с тем, что его участие в голосовании может быть обработано для улучшения работы Платформы, но в анонимизированной форме.
    <p>11. Форс-мажор</p>
    11.1. Компания не несет ответственности за задержки или невыполнение обязательств по настоящему Соглашению, если это вызвано обстоятельствами непреодолимой силы (форс-мажор), включая, но не ограничиваясь, стихийные бедствия, пожары, наводнения, войны, военные действия, террористические акты, забастовки, блокировки, действия государственных органов, а также любые иные обстоятельства, находящиеся вне разумного контроля Компании.
    11.2. В случае наступления таких обстоятельств Компания обязуется уведомить Пользователей о возникновении форс-мажорных обстоятельств и приложить все усилия для минимизации негативных последствий.
    <p>12. Внесение Изменений в Соглашение</p>
    12.1. Компания оставляет за собой право вносить изменения в настоящее Соглашение в одностороннем порядке.
    12.2. В случае внесения изменений в Соглашение, Компания уведомит Пользователей через соответствующие каналы связи, включая, но не ограничиваясь, электронную почту, уведомления в аккаунте Пользователя на Платформе или через другие средства связи. Уведомление будет направлено за 14 календарных дней до вступления изменений в силу.
    12.3. Продолжение использования Платформы после вступления изменений в силу означает согласие Пользователя с обновлённым Соглашением. Пользователи обязуются периодически проверять условия Соглашения на предмет возможных изменений.
    <p>13. Отказ от Ответственности</p>
    13.1. Пользователь соглашается с тем, что результаты голосования могут не совпадать с его личными предпочтениями или ожиданиями.
    13.2. Компания уведомляет, что не несет ответственности за результаты голосования, и Пользователь принимает это условие, выражая своё согласие с данным Соглашением.
    13.3. Пользователь отказывается от всех претензий, связанных с результатами голосования, в отношении Компании, её администрации, а также всех аффилированных лиц.
    <p>14. Отказ от настоящего Соглашения</p>
    14.1. Пользователь может прекратить действие настоящего Соглашения в любой момент по своему усмотрению. Для этого Пользователю необходимо уведомить Компанию, отправив письменное уведомление на официальный адрес электронной почты office@autentic.capital. Прекращение Соглашения вступает в силу через 14 календарных дней с момента получения уведомления Компанией.
    14.2. С момента вступления прекращения Соглашения в силу Пользователь теряет возможность участвовать в любых текущих или будущих голосованиях на Платформе, получать вознаграждение по процессу голосования. Все незавершенные голосования, в которых участвует Пользователь, будут аннулированы, и токены, использованные для голосования, будут возвращены на аккаунт Пользователя.
    14.3. После прекращения Соглашения Платформа сохраняет историю голосования и все связанные данные Пользователя в соответствии с политикой конфиденциальности.
    14.4. Пользователь имеет право вновь заключить Соглашение о голосовании, отправив письменное уведомление на официальный адрес электронной почты Компании office@autentic.capital.
    14.5. Компания оставляет за собой право прекратить действие настоящего Соглашения в отношении конкретного Пользователя в случае нарушения им условий Соглашения, предоставления недостоверной информации или по иным другим причинам. В таком случае Компания уведомит Пользователя о прекращении Соглашения, отправив соответствующее сообщение любым доступным способом по усмотрению Компании.
    <p>15. Заключительные Положения</p>
    15.1. Настоящее Соглашение может быть изменено администрацией Платформы, о чём Пользователи будут уведомлены через соответствующие каналы связи.
    15.2. В случае возникновения споров или разногласий, связанных с выполнением или толкованием настоящего Соглашения, стороны обязуются предпринимать все разумные усилия для их разрешения путём переговоров.
    15.3. Если спор не может быть разрешен путем переговоров в течение 5 календарных дней с момента его возникновения, стороны могут обратиться к процедуре медиации. Стороны вправе выбрать Медиатора самостоятельно и предложить медиатора противоположной стороне для согласования. Если медиатор, предложенный противоположной стороне, не устраивает по какой-либо причине два раза, стороны договорились считать третьего медиатора окончательным.
    15.4. Решение, принятое медиатором, является окончательным и обязательным для обеих сторон.
    15.5. Платформа доступна по адресу https://cabinet.autentic.capital/, где пользователи могут получить доступ к кабинету инвестора и разделу голосования за проекты после регистрации на платформе и депозита токена AUT.
    15.6. Пользователи могут обращаться в службу поддержки по адресу электронной почты support@autentic.capital для получения помощи по вопросам, связанным с использованием Платформы, в том числе при возникновении технических проблем, сложностей с регистрацией, интеграцией токенов AUT, голосованием или доступом к личному кабинету. Компания стремится отвечать на обращения Пользователей в течение двух рабочих дней с момента их получения. В случае, если для решения вопроса требуется больше времени, Пользователь будет уведомлен о статусе его обращения и предполагаемых сроках его разрешения.


  </span>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TermsVote",
  computed: {
    ...mapGetters({
      language: "language"
    })
  }
};
</script>

<style scoped>

</style>