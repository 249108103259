<template>
  <div
    v-if="$route.name ==='project card'"
    id="project-page"
    class="page-wrapper"
  >
    <div
      class="main-panel"
    >
      <ProgressLoader
        v-if="loading"
        mode="indeterminate"
        color="#5AC396"
        size="150px"
      />
      <div
        v-else-if="project"
        :isOuter="isOuter"
        class="project-page"
      >
        <AuBreadcrumb
          :parent-path="parentPath"
          parent-name="router.capital"
          :current-page-title="project.name"
        />
        <div class="project-page-wrapper">
          <div
            class="card project-card"
            :class="isTester ? '' : 'tester'"
          >
            <div class="project-cover">
              <img
                v-if="images.length == 1 && project.icon"
                class="project-image"
                :src="project.icon"
                :alt="project.name"
              >

              <Swiper
                v-else-if="images.length > 1"
                :navigation="true"
                :hash-navigation="{
                  watchState: true,
                }"
                :modules="modules"
              >
                <SwiperSlide
                  v-for="(file, index) in images"
                  :key="index"
                >
                  <ProjectCardEmbeddedImage
                    v-if="file.isVideoEmbedded"
                    :embedded-code="file.embeddedCode"
                    :src="file.thumb"
                    :alt="file.nameOriginal"
                    target-class=".project-image"
                    main-class=".main-panel"
                  />

                  <img
                    v-else
                    class="project-image"
                    :src="file.thumb"
                    :alt="file.nameOriginal"
                  >
                </SwiperSlide>
              </Swiper>

              <div
                v-else
                class="no-image-wrap"
              >
                <div
                  class="no-image"
                >
                  <img
                    src="/img/project-card/no-photo.svg"
                    :alt="project.name"
                  >
                </div>
              </div>

              <div class="info">
                <div>
                  <div
                    class="status"
                    :style="style"
                  >
                    <AuIcon :icon="project.statusIcon" /> <span>{{ project.status }}</span>
                  </div>
                  <div
                    v-if="isVoting"
                    class="status position-absolute mt-2"
                    :style="voteStyle"
                  >
                    <AuIcon icon="vote" /> <span>Voting</span>
                  </div>
                </div>

                <div class="tag">
                  {{ project.tag }}
                </div>
              </div>
            </div>

            <div
              v-if="!isAllowed"
              :style="warningStyle"
              class="project-warning"
            >
              <div class="content">
                <img
                  src="/img/project-card/warning-icon.svg"
                  alt="warning"
                >
                <span>
                  {{ $t("auCapitals.projectCard.notAllowed") }}
                </span>
              </div>
            </div>

            <div class="project-header">
              <h1>
                {{ project.name }}
              </h1>
              <div class="timer-place">
                <AuButton
                  class="invest-button"
                  bordered
                  width="153px"
                  height="52px"
                  center
                  active-bg-color="#279063"
                  :disabled="project.status !== 'Open' || !isAllowed"
                  @click="showHide"
                >
                  {{ $t(`auCapitals.projectCard.buttonInvest${vote}`) }}
                </AuButton>
                <div v-if="timer">
                  {{ timer }}
                </div>
              </div>
            </div>

            <div
              v-if="!isVoting"
              class="project-info-short"
            >
              <div class="line">
                <div class="link-item">
                  <AuIcon icon="common_stok" />
                  <span>
                    {{ $t("auCapitals.projectCard.commonStock") }}
                  </span>
                </div>
                <div
                  v-if="correctToken.blockChain"
                  class="link-item"
                >
                  <img
                    :src="correctToken.blockChain.logotype"
                    class="blockchain-img"
                  >
                  <span>
                    {{ correctToken.blockChain.name }}
                  </span>
                </div>
                <div class="link-item">
                  <img
                    :src="logoIcon"
                    class="blockchain-img"
                  >
                  <a
                    :href="link + correctToken?.createdAddress"
                    target="_blank"
                  >
                    <span class="link-item underline">
                      {{ correctToken.mnemonicEng }}
                    </span>
                  </a>
                </div>
              </div>
              <div class="line second">
                <div class="link-item">
                  <img
                    src="/img/project-card/price-icon.svg"
                    alt="price"
                  >
                  <span>
                    {{ $t("auCapitals.projectCard.priceToken") }} —
                  </span>
                  <span class="value">
                    <AuDecimalStatic
                      :value="project.priceBase"
                      :decimal="project.mnemonicDecimals"
                      :indent="project.mnemonicIndent"
                      :addition="project.mnemonicEng"
                      format-value
                      show-addition
                      smart-indent
                    />
                  </span>
                </div>
              </div>
            </div>

            <div class="sep-line" />

            <div class="project-progress">
              <div
                :style="infoStyle"
                class="under-info"
              >
                <div
                  v-if="!isVoting"
                  :style="softStyle"
                  class="info-wrapper soft"
                >
                  <span class="label">
                    {{ $t("auCapitals.projectCard.softCap") }}
                  </span>
                  <span class="value">
                    <AuDecimalStatic
                      :value="project.minCap"
                      :addition="project.mnemonicEng"
                      :decimal="project.mnemonicDecimals"
                      :indent="project.mnemonicIndent"
                      smart-indent
                    />
                  </span>
                </div>

                <div
                  class="info-wrapper hard"
                  :voting="isVoting"
                >
                  <span class="label">
                    {{ $t(`auCapitals.projectCard.hardCap${vote}`) }}
                  </span>
                  <span class="value">
                    <AuDecimalStatic
                      :value="project.maxCap"
                      :addition="project.mnemonicEng"
                      :decimal="project.mnemonicDecimals"
                      :indent="project.mnemonicIndent"
                      smart-indent
                    />
                  </span>
                </div>
              </div>
              <ProgressBar
                class="project-progressbar"
                :procent="project.progressProcent"
                :status="project.status"
              />
              <div class="above-info">
                <div
                  :style="amountStyle"
                  class="info-wrapper raised"
                >
                  <span class="label">
                    {{ $t(`auCapitals.projectCard.amountRaised${vote}`) }}
                  </span>
                  <span class="value">
                    <AuDecimalStatic
                      :value="project.currentCap"
                      :addition="project.mnemonicEng"
                      :decimal="project.mnemonicDecimals"
                      :indent="project.mnemonicIndent"
                      smart-indent
                    />
                  </span>
                </div>
              </div>
            </div>

            <div class="grouped-block">
              <div
                v-if="project.minCap > project.currentCap"
                class="project-notification"
              >
                <div class="content">
                  <img
                    src="/img/project-card/warning-icon.svg"
                    alt="warning"
                  >
                  <span>
                    {{ $t(`auCapitals.projectCard.note${vote}`) }}
                  </span>
                </div>
              </div>
            </div>

            <div class="project-description">
              {{ project.descriptionEng }}
            </div>

            <div class="sep-line" />

            <div class="project-info-detailed">
              <h3 class="title">
                {{ $t("auCapitals.projectCard.information.information") }}
              </h3>
              <div class="info-table">
                <div class="table-body">
                  <div class="table-column">
                    <div class="column-line">
                      <div class="label">
                        {{ $t("auCapitals.projectCard.information.country") }}
                      </div>
                      <div class="sep" />
                      <div
                        v-if="country"
                        class="value"
                      >
                        {{ country }}
                      </div>
                      <div
                        v-else
                        class="label"
                      >
                        {{ $t("auCapitals.projectCard.notSet") }}
                      </div>
                    </div>
                    <div class="column-line">
                      <div class="label">
                        {{ $t("auCapitals.projectCard.information.SPV") }}
                      </div>
                      <div class="sep" />
                      <div
                        v-if="project.company"
                        class="value"
                      >
                        {{ project.company }}
                      </div>
                      <div
                        v-else
                        class="label"
                      >
                        {{ $t("auCapitals.projectCard.notSet") }}
                      </div>
                    </div>
                    <div class="column-line">
                      <div class="label">
                        {{ $t("auCapitals.projectCard.information.date") }}
                      </div>
                      <div class="sep" />
                      <div class="value">
                        {{ project.dateStart }} - {{ project.dateEnd }}
                      </div>
                    </div>
                    <div class="column-line">
                      <div class="label">
                        {{ $t("auCapitals.projectCard.information.dividends") }}
                      </div>
                      <div class="sep" />
                      <div class="value yes">
                        {{ $t("auCapitals.projectCard.information.yes") }}
                      </div>
                    </div>
                    <div
                      v-if="minQuote"
                      class="column-line align-items-baseline"
                    >
                      <div class="label">
                        {{ $t("auCapitals.projectCard.minimumSize") }}
                      </div>
                      <div class="sep" />
                      <div class="d-flex flex-column">
                        <AuDecimalStatic
                          :value="minQuote"
                          :decimal="0"
                          :addition="project.mnemonicEng"
                          class="value"
                          smart-indent
                        />
                        <AuDecimalStatic
                          :value="minQuoteMulPrice"
                          :decimal="0"
                          :addition="correctToken?.mnemonicEng"
                          class="value"
                          smart-indent
                        />
                      </div>
                    </div>
                  </div>
                  <div class="table-column">
                    <div class="column-line">
                      <div class="label">
                        {{ $t("auCapitals.projectCard.information.equity") }}
                      </div>
                      <div class="sep" />
                      <div class="value yes">
                        {{ $t("auCapitals.projectCard.information.yes") }}
                      </div>
                    </div>
                    <div class="column-line">
                      <div class="label">
                        {{ $t("auCapitals.projectCard.information.redemption") }}
                      </div>
                      <div class="sep" />
                      <div class="value no">
                        {{ $t("auCapitals.projectCard.information.no") }}
                      </div>
                    </div>
                    <div class="column-line">
                      <div class="label">
                        {{ $t("auCapitals.projectCard.information.votingRight") }}
                      </div>
                      <div class="sep" />
                      <div class="value no">
                        {{ $t("auCapitals.projectCard.information.no") }}
                      </div>
                    </div>
                    <div class="column-line">
                      <div class="label">
                        {{ $t("auCapitals.projectCard.information.specialConditions") }}
                      </div>
                      <div class="sep" />
                      <div class="value no">
                        {{ $t("auCapitals.projectCard.information.no") }}
                      </div>
                    </div>
                    <div
                      v-if="maxQuote"
                      class="column-line align-items-baseline"
                    >
                      <div class="label">
                        {{ $t("auCapitals.projectCard.maximumSize") }}
                      </div>
                      <div class="sep" />
                      <div class="d-flex flex-column">
                        <AuDecimalStatic
                          :value="maxQuote"
                          :decimal="0"
                          :addition="project.mnemonicEng"
                          class="value"
                          smart-indent
                        />
                        <AuDecimalStatic
                          :value="maxQuoteMulPrice"
                          :decimal="0"
                          :addition="correctToken?.mnemonicEng"
                          class="value"
                          smart-indent
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="!isOuter"
              class="invest-history"
            >
              <RouterLink
                :to="`/project-card/${isVoting?'vote-history':'capital-history'}/${project.id}`"
              >
                {{ $t(`auCapitals.projectCard.history${vote}`) }}
              </RouterLink>
            </div>

            <template v-if="!isOuter && documents.length">
              <div class="sep-line" />

              <div class="project-documents">
                <h3 class="title">
                  {{ $t("auCapitals.projectCard.documents.documents") }}
                </h3>

                <div class="project-documents-list">
                  <div
                    v-for="(file, index) in documents"
                    :key="index"
                    class="document"
                  >
                    <a
                      :href="file.name"
                      target="_blank"
                    >
                      <img
                        src="/img/project-card/file-icon.svg"
                        alt="file-icon"
                      >
                      <span>
                        {{ file.nameOriginal }}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div
            v-if="similarProjects.length"
            class="similar-projects"
          >
            <h1>
              {{ $t("auCapitals.projectCard.similarProjects") }}
            </h1>
            <div
              v-for="proj in similarProjects"
              :key="proj.id"
              class="card similar-project"
            >
              <img
                v-if="proj.icon"
                class="similar-project__image"
                :src="proj.icon"
                :alt="proj.tokenBase.nameEng"
              >

              <div
                v-else
                class="no-image-wrap"
              >
                <div
                  class="no-image"
                >
                  <img
                    src="/img/project-card/no-photo.svg"
                    :alt="proj.tokenBase.nameEng"
                  >
                </div>
              </div>

              <div class="similar-project__info">
                <span class="fs18">
                  {{ proj.tokenBase.nameEng }}
                </span>

                <div class="flex fs12">
                  <span>
                    {{ $t("auCapitals.projectCard.from") }} <AuDecimalStatic
                      :value="proj?.priceBase"
                      :addition="proj?.tokenQuote?.mnemonicEng"
                      :decimal="proj?.tokenQuote?.decimals"
                      :indent="proj?.tokenQuote?.indent"
                      smart-indent
                    />
                  </span>

                  <span class="profitability">
                    {{ $t("auCapitals.projectCard.profitability") }}: 40%
                  </span>
                </div>
              </div>

              <div>
                <RouterLink
                  :to="`${proj.id}`"
                  class="similar-project__button-text"
                >
                  <AuButton
                    bordered
                    center
                    height="39px"
                  >
                    {{ $t("auCapitals.projectCard.more") }}
                  </AuButton>
                </RouterLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CapitalModal
      v-if="showDialog"
      v-model="showDialog"
      :project="project"
    />
  </div>
  <RouterView v-else />
</template>

<script>
import _ from "lodash";
import BigNumber from "bignumber.js";

import CapitalModal from "@/components/capital/CapitalModal";
import MetaMask from "@/mixins/MetaMask";
import ProgressBar from "@/components/progressBar/ProgressBar";
import ProgressLoader from "@/loaders/progress-bar";
import ProjectCardEmbeddedImage from "@/components/capital/ProjectCardEmbeddedImage";
import { convertCapital } from "@/components/capital/capitalHelper";

import moment from "moment/moment";
import { mapActions, mapGetters } from "vuex";

import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";

export default {
  name: "ProjectCard",

  components: {
    ProgressBar,
    CapitalModal,
    ProgressLoader,
    ProjectCardEmbeddedImage,
    Swiper,
    SwiperSlide
  },

  mixins: [MetaMask],

  data() {
    return {
      project: {},
      showDialog: false,
      descriptionMode: 1,
      correctToken: {},
      loading: true,
      similarProjects: [],
      timer: null,
      modules: [Navigation]
    };
  },

  computed: {
    ...mapGetters({
      sectionCapital: "sectionCapital",
      auOwners: "auOwners",
      uloadFile: "files/uploadedFile",
      language: "language",
      profile: "profile/profile",
      countries: "country",
      isLightTheme: "isLightTheme",
      checkProfile: "profile/checkProfile"
    }),

    projectId() {
      return this.$route.params.id;
    },

    isAllowed() {
      if (!this.project.allowedForUsers) {
        return true;
      }
      return _.some(this.profile.roles, role => role == this.project.allowedForUsers);
    },

    warningStyle() {
      if (this.isLightTheme) {
        return {
          "--filter": "invert(91%) sepia(13%) saturate(3084%) hue-rotate(302deg) brightness(109%) contrast(101%)",
          "--border-color": "#ffc59c"
        };
      }

      return {
        "--filter": "invert(36%) sepia(100%) saturate(1005%) hue-rotate(348deg) brightness(81%) contrast(96%)",
        "--border-color": "#c55018"
      };
    },

    softStyle() {
      const percent = this.getPercentage(this.project.minCap, this.project.maxCap);

      let stype = {
        "margin-left": (percent > 70 ? 70 : percent) + "%",
        "text-align": percent > 70 ? "end" : "start"
      };

      if (percent <= 70) {
        stype["border-left"] = "2px rgba(40, 229, 147, 0.2509803922) solid";
        stype["padding-left"] = "5px";
      }
      else {
        stype["border-right"] = "2px rgba(40, 229, 147, 0.2509803922) solid";
        stype["padding-right"] = "5px";
        stype["margin-right"] = "5px";
      }

      return stype;
    },

    infoStyle() {
      const percent = this.getPercentage(this.project.minCap, this.project.maxCap);
      if (percent > 70) {
        return {
          "justify-content": "end"
        };
      }

      return {

      };
    },

    amountStyle() {
      const percent = this.getPercentage(this.project.currentCap, this.project.maxCap);

      let stype = {
        "margin-left": (percent > 85 ? 0 : percent) + "%",
        "text-align": percent > 85 ? "end" : "start"
      };

      if (percent > 85) {
        stype.width = "100%";
      }

      return stype;
    },

    link() {
      return `${process.env.VUE_APP_SCAN_BASE_URL}token/`;
    },

    style() {
      return {
        "--status-color": this.project.statusColor
      };
    },

    voteStyle() {
      return {
        "--status-color": "#D29C1E"
      };
    },

    isOuter() {
      return this.$route.meta.outer == true;
    },

    parentPath() {
      return this.isOuter ? "/outer-projects" : "/projects";
    },

    priceBase() {
      let value = BigNumber(this.project.priceBase);
      value = value.dividedBy(BigNumber(10).pow(this.project.mnemonicDecimals));
      return value.toString(10);
    },

    maxQuote() {
      const value = BigNumber(this.project.quote).multipliedBy(this.priceBase);
      return value.isNaN() ? NaN : value.toString(10);
    },

    minQuote() {
      const value = BigNumber(this.project.minCount).multipliedBy(this.priceBase);
      return value.isNaN() ? NaN : value.toString(10);
    },

    maxQuoteMulPrice() {
      return BigNumber(this.maxQuote).multipliedBy(this.priceBase).toString(10);
    },

    minQuoteMulPrice() {
      return BigNumber(this.minQuote).multipliedBy(this.priceBase).toString(10);
    },

    logoIcon() {
      return (this.correctToken?.logoMain ?? this.correctToken?.mainImage?.name) ?? "/svg/companies/s_logo.svg";
    },

    countryOptions() {
      return _.map(this.countries, c => ({ display: c.name, value: c.code }));
    },

    country() {
      return _.chain(this.countryOptions)
        .find({ value: this.project.countryCode })
        .get("display")
        .value();
    },

    images() {
      const images = _.filter(this.project.files, file => file.type == "image" || file.isVideoEmbedded);

      if (this.project.icon) {
        return [{ thumb: this.project.icon, nameOriginal: this.project.name }, ...images];
      }

      return images;
    },

    documents() {
      return _.filter(this.project.files, file => file.type != "image" && !file.isVideoEmbedded);
    },

    isTester() {
      return this.project?.visibility;
    },

    isVoting() {
      return this.project.projectType === "voting";
    },

    vote() {
      return this.isVoting ? "Vote" : "";
    }
  },

  watch: {
    projectId: {
      immediate: true,
      async handler() {
        const capitals = _.cloneDeep(this.sectionCapital);

        this.loading = true;

        await this.getSectionCapital();
        await this.getCapital();
        await this.getSimilarProjects();
        await this.startTimer();

        this.loading = false;

        this.$nextTick(() => {
          if (capitals.length != 0) {
            this.windowResize();
          }
        });
      }
    },
    checkProfile: {
      immediate: true,
      async handler() {
        await this.finish();
      }
    }
  },

  async created() {
    await this.getCountry();
  },

  methods: {
    ...mapActions({
      getSectionCapital: "getSectionCapital",
      getAuCapitals: "capitals/getAuCapitals",
      getAuCapitalsBySectorId: "capitals/getAuCapitalsBySectorId",
      getUploadedFile: "files/getUploadedFile",
      getCountry: "getCountry"
    }),

    windowResize() {
      const element = document.getElementById("project-page");

      if (element) {
        window.parent.postMessage({ height: element.offsetHeight + 4 + "px", scrollTop: true }, "*");
      }
    },

    async getSimilarProjects() {
      let params = {
        sectionId: this.project.section,
        status: "open,wating",
        sort: "createdAt",
        order: "desc"
      };
      const similarProjects = await this.getAuCapitalsBySectorId(params);

      this.similarProjects = _.chain(similarProjects).reject({ id: this.project.id }).take(2).value();

      for (let index = 0; index < this.similarProjects.length; index++) {
        if (this.similarProjects[index].mainImage) {
          this.similarProjects[index].icon = await this.getImgByIri(this.similarProjects[index].mainImage);
        }
      }
    },

    getPercentage(dividend, divider) {
      const percentage = BigNumber(dividend).dividedBy(divider).multipliedBy(100);
      if (percentage.isGreaterThanOrEqualTo(100)) {
        return 100;
      }

      return Math.round(percentage.toNumber());
    },

    async getCapital() {
      try {
        if (!this.$route.params.id) {
          return;
        }

        const item = await this.getAuCapitals(this.$route.params.id);
        this.project = convertCapital(item, this.sectionCapital);
        this.correctToken = item.tokenBase;
      }
      catch (e) {
        console.log("error", e);
      }
    },

    async finish() {
      const item = await this.getAuCapitals(this.$route.params.id);
      this.project.currentCap = item.currentCap;
      let percent = BigNumber(this.project.currentCap);
      percent = percent.multipliedBy(100);
      percent = percent.dividedBy(this.project.maxCap);
      this.project.progressProcent = percent.toNumber().toFixed(1);
    },

    showHide() {
      if (this.isOuter) {
        window.open(process.env.VUE_APP_HOST_API + "project-card/" + this.project.id + "?outer=true", "_blank");
      }
      else {
        this.showDialog = !this.showDialog;
      }
    },

    showSuccess() {
      this.$toast.success(this.$t("auCapitals.capitalCard.success"));
    },

    showError(message) {
      this.$toast.error(message);
    },

    async getImgByIri(iri) {
      await this.getUploadedFile(iri.id);
      return this.uloadFile.name;
    },

    async startTimer() {
      const waiting = moment().isBefore(this.project.rawStart) ? {
        moment: moment(this.project.rawStart),
        status: "Open",
        icon: "rocketc",
        color: "#2aba7c",
        text: "Opens in"
      } :
        (moment().isBefore(this.project.rawEnd) ? {
          moment: moment(this.project.rawEnd),
          status: "Closed",
          icon: "rocketc",
          color: "#C0C0C0",
          text: "Closes in"
        } :
          null);

      if (waiting) {
        const diff = waiting.moment.diff(moment(), "seconds");
        let ticker = diff < (24 * 60 * 60) && diff > 0 ? moment.duration(diff, "seconds") : null;
        this.timer = ticker ? this.formatTimer(waiting.text, ticker) : null;

        const intervalHandle = setInterval(() => {
          if (moment().isAfter(waiting.moment)) {
            this.project.status = waiting.status;
            this.project.statusIcon = waiting.icon;
            this.project.statusColor = waiting.color;
            this.timer = null;
            ticker = null;
            clearInterval(intervalHandle);
            if (waiting.status === "Open") {
              this.startTimer();
            }
          }

          if (ticker) {
            ticker = ticker.subtract(1, "seconds");
            this.timer = this.formatTimer(waiting.text, ticker);
          }
        }, 1000);
      }
    },

    formatTimer(text, duration) {
      let hour = duration.hours();
      let minutes = duration.minutes();
      let seconds = duration.seconds();
      hour = hour < 10 ? `0${hour}` : hour;
      minutes = minutes < 10 ? `0${minutes}` : minutes;
      seconds = seconds < 10 ? `0${seconds}` : seconds;
      return text + ` ${hour}:${minutes}:${seconds}`;
    }
  }
};
</script>

<style lang="scss">
button.btn.invest-button {
  div.btn__text {
    color: white;
  }
}
.project-page {
  p {
    margin-top: 0;
    margin-bottom: 0;
  }

  .project-cover {
    @media screen and (max-width: 576px) {
      .swiper-button-next {
        scale: 0.7;
      }

      .swiper-button-prev {
        scale: 0.7;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.project {
  &-page {
    &[isOuter=true] {
      padding: 20px;
    }

    &[isOuter=false] {
      margin-top: 25px;
    }

    &-wrapper {
      display: flex;
      gap: 25px;

      @media screen and (max-width:1300px) {
        flex-direction: column;
      }

      .no-image-wrap {
        position: relative;
        width: 100%;

        &:before{
          content: "";
          display: block;
          padding-top: 50%;
        }

        .no-image {
          position:  absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;

          display: flex;
          justify-content: center;
          align-items: center;
          background: var(--color-10);
          border-radius: 15px;
        };
      }

      .project-card {
        max-width: 840px;
        width: 60%;
        height: 100%;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 25px;

        @media screen and (max-width:1300px) {
          width: 100%;
        }

        .invest-history {
          display: flex;
          justify-content: center;

          a {
            cursor: pointer;
            text-decoration: underline;
            color: var(--text-color);
          }
        }

        .sep-line {
          width: 100%;
          height: 3px;
          background-color: var(--sep-line-color);
        }

        .grouped-block {
          display: flex;
          flex-direction: column;
          gap: 15px;
        }
        .project {
          &-cover {
            position: relative;
            .project-image {
              width: 100%;
              max-width: 800px;
              max-height: 400px;
              border-radius: 15px;
              object-fit: cover;
            }

            .info {
              position: absolute;

              display: flex;
              justify-content: space-between;
              gap: 10px;

              width: 100%;
              top: 15px;
              left: 15px;
              padding-right: 30px;

              z-index: 50;
              .status {
                display: flex;
                gap: 5px;
                color: white;
                padding: 12px;
                background-color: var(--status-color);
                border-radius: 12px;
                min-width: 90px;
                height: 45px;

                span {
                  white-space: pre;
                }
              }
              .tag {
                background-color: white;
                color: black;
                padding: 12px;
                border-radius: 12px;

              }
            }
          }

          &-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;

            @media screen and (max-width: 576px) {
              flex-direction: column;
              gap: 20px;
              align-items: baseline;
              .invest-button {
                width: 100%;
              }
            }

            h1 {
              color: var(--text-color);
              font-size: 24px;
            }
          }

          &-info {
            &-short {
              display: flex;
              flex-direction: column;
              gap: 15px;

              @media screen and (max-width:1320px) {
                .second {
                  flex-direction: column;
                }
              }

              .blockchain-img {
                border-radius: 50%;
                height: 24px;
                width: 24px;
              }

              .line {
                display: flex;
                gap: 15px;
                flex-wrap: wrap;

                .link-item {
                  color: var(--text-color);
                  display: flex;
                  align-items: center;
                  gap: 5px;
                  &.link {
                    color: #2ABA7C;
                    cursor: pointer;
                  }
                  .value {
                    color: #2ABA7C;
                  }
                  .underline {
                    text-decoration: underline;
                  }
                }
              }
            }
            &-detailed {
              display: flex;
              flex-direction: column;
              gap: 15px;
              .title {
                color: var(--text-color);
                font-size: 16px;
                font-weight: 500;
              }
              .info-table {
                display: flex;
                flex-direction: column;
                gap: 8px;
                .table-body {
                  display: grid;
                  grid-template-columns: 1fr 1fr;
                  gap: 25px;

                  @media screen and (max-width:576px) {
                    grid-template-columns: 1fr;
                    gap: 12px;
                  }

                  .table-column {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 12px;
                    .column-line {
                      display: flex;
                      gap: 10px;
                      align-items: flex-end;
                      width: 100%;
                      .label {
                        text-wrap: nowrap;
                        font-size: 12px;
                        color: var(--color-50);
                        &.link {
                          color: #279063;
                          cursor: pointer;
                        }
                      }
                      .sep {
                        height: 2px;
                        flex: 1;
                        border-bottom: 1px dashed var(--color-50);
                        margin-bottom: 4px;
                        opacity: 0.5;
                      }
                      .value {
                        font-size: 12px;
                        color: var(--text-color);
                        text-wrap: nowrap;
                        text-align: end;

                        &.yes {
                          display: flex;
                          align-items: center;
                          gap: 4px;
                          &::after {
                            content: url("/public/img/project-card/success-mark.svg");
                            position: relative;
                            bottom: -2px;
                          }
                        }
                        &.no {
                          display: flex;
                          align-items: center;
                          gap: 4px;
                          &::after {
                            content: url("/public/img/project-card/no-mark.svg");
                            position: relative;
                            bottom: -2px;
                          }
                        }
                      }
                    }
                  }
                }
                .table-footer {
                  .column-line {
                    display: flex;
                    gap: 10px;
                    align-items: flex-end;
                    width: 100%;
                    .label {
                      text-wrap: nowrap;
                      font-size: 12px;
                      color: var(--color-50);
                      &:has(>img) {
                        display: flex;
                        gap: 3px;
                        align-items: center;
                        color: #279063;
                      }
                    }
                    .sep {
                      height: 2px;
                      flex: 1;
                      border-bottom: 1px dashed var(--color-50);
                      margin-bottom: 4px;
                      opacity: 0.5;
                    }
                    .value {
                      font-size: 12px;
                      color: var(--text-color);
                      text-wrap: nowrap;
                      padding: 3px 8px;
                      background-color: #2ABA7C15;
                      border-radius: 5px;
                    }
                  }
                }

              }
            }
          }

          &-progress {
            display: flex;
            flex-direction: column;
            gap: 5px;
            margin-top: -12px;
            .under-info {
              display: flex;
              justify-content: space-between;
            }
            .info-wrapper {
              display: flex;
              flex-direction: column;
              width: fit-content;
              .label {
                font-size: 12px;
                color: var(--color-50);
              }
              .value {
                font-size: 15px;
                color: var(--text-color);
              }
              &[voting="true"] {
                width: 100%;
                align-items: end;
              }
            }
          }

          &-warning {
            border: 4px solid var(--border-color);
            border-radius: 14px;

            padding: 10px;
            background-color: var(--sep-line-color);
            color: var(--text-color);

            .content {
              display: flex;
              align-items: center;
              gap: 10.5px;
              img {
                width: 34px;
                height: 34px;
                filter: var(--filter);
              }
              span {
                font-weight: 500;
                font-size: 16px;
                line-height: 1.2;
              }
            }
          }

          &-notification {
            padding: 15px;
            background-color: var(--sep-line-color);
            color: var(--text-color);
            .content {
              display: flex;
              align-items: center;
              gap: 10.5px;
              img {
                width: 34px;
                height: 34px;
                filter: invert(91%) sepia(13%) saturate(3084%) hue-rotate(302deg) brightness(109%) contrast(101%);
              }
              span {
                font-weight: 500;
                font-size: 13px;
              }
            }
          }

          &-raised {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            gap: 50px;
            color: var(--text-color);

            @media screen and (max-width:1320px) {
              gap: 10px;
            }

            .title {
              color: var(--text-color);
              font-size: 16px;
              font-weight: 500;
            }
            .values {
              display: flex;
              gap: 15px;
              .value {
                display: flex;
                align-items: center;

                gap: 5px;
                .label {
                  font-weight: 500;
                }
                .amount {
                  font-weight: bold;
                  &[success=true] {
                    color: #2ABA7C;
                  }
                }
              }
            }
          }

          &-description {
            font-weight: bold;
            font-size: 12px;
            color: var(--color-50);
            white-space: pre-line;
            p {
              margin: 0;
            }
          }

          &-documents {
            display: flex;
            flex-direction: column;
            gap: 15px;
            .title {
              color: var(--text-color);
              font-size: 16px;
              font-weight: 500;
            }
            &-list {
              display: flex;
              flex-wrap: wrap;
              gap: 15px;
              .document {
                padding: 15px;
                justify-content: flex-start;
                align-items: center;
                width: 255px;
                border: 1px solid var(--color-50);
                border-radius: 10px;
                cursor: pointer;

                @media screen and (max-width:576px) {
                  width: auto;
                }

                &:hover {
                  background: #5ac3961c;
                }

                &:active {
                  background: #5ac3963a;
                }

                a {
                  display: flex;
                  align-items: center;
                  gap: 4px;
                  text-decoration: none;
                  span {
                    color: var(--text-color);
                    span {
                      font-weight: bold;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .similar-projects {
        width: 40%;

        @media screen and (max-width:1100px) {
          width: 100%;
        }

        h1 {
          font-size: 24px;
          margin-bottom: 20px;;
        }

        .similar-project {
          padding: 20px;
          width: 100%;
          max-width: 840px;

          margin-bottom: 20px;

          &__image {
            border-radius: 15px;

            @media screen and (max-width:1100px) {
              width: 100%;
            }
          }

          &__button-text  {
            font-size: 11px;
            color: var(--btn-ft-color-dis);
          }

          &__info {
            display: flex;
            flex-direction: column;

            gap: 12px;

            margin: 20px 0px;

            color: var(--text-color);

            .profitability{
              color: var(--color-50);

              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}
.timer-place {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  color: #30BC89;
  font-weight: 600;
  @media screen and (max-width: 576px) {
    width: 100%;
    .invest-button {
      width: 100%;
    }
  }
}
.tester {
  background: var(--tester-color);
}
</style>