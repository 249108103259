<template>
  <div class="user-view">
    <div class="form-and-image">
      <div class="left-block">
        <div class="title">
          <span class="header1">
            {{ $t(header1) }}
          </span>
          <span class="header2">
            {{ $t(header2) }}
          </span>
          <span class="header3">
            {{ $t(header3) }}
          </span>
        </div>
        <div class="laptop">
          <div
            v-if="!showLaptop"
            class="plug"
          />
          <img
            v-show="showLaptop"
            class="laptop-img"
            :src="`/img/login/${imgLaptop}.png`"
            alt=""
            @load="showLaptop=true"
          >
          <div class="green-place">
            <div class="rectangle line1" />
            <div class="rectangle line2" />
            <div class="rectangle line3" />
            <div class="rectangle line4" />
            <div class="rectangle line5" />
          </div>
        </div>
      </div>
      <div>
        <div class="panel">
          <AuIcon
            class="logo-scale"
            :icon="mainLogo"
          />
          <SocialNetwork
            v-if="socialBtn"
            class="top"
          />
          <div class="select">
            <div
              class="tab-select"
              :class="{ checked: isLogin }"
              @click="changeTab"
            >
              {{ $t("login.enter") }}
            </div>
            <div
              class="tab-select"
              :class="{ checked: !isLogin }"
              @click="changeTab"
            >
              {{ $t("login.register") }}
            </div>
          </div>
          <div
            v-if="isVerify == 'error' && isLogin"
            class="error-verify"
          >
            Invalid or outdated confirmation link!
          </div>
          <div
            v-if="isVerify == 'success' && isLogin"
            class="success-verify"
          >
            Email successfully confirmed!
          </div>
          <div>
            <!-- <span class="radio">
              <input
                type="radio"
                name="login"
                value="email"
                checked
                @change="isEmail=!isEmail"
              >&nbsp;E-mail
            </span>
            <span class="radio">
              <input
                type="radio"
                name="login"
                value="phone"
                @change="isEmail=!isEmail"
              >&nbsp;Телефон
            </span> -->
          </div>
          <span
            v-if="!isLogin && !allField"
            class="text-explain"
          >{{ $t("login.requiredFields") }}</span>
          <input
            v-if="isEmail"
            v-model="email"
            class="data-in"
            type="text"
            placeholder="E-mail *"
          >
          <div
            v-if="!isLogin"
            class="error-block-wrapper"
          >
            <div
              v-for="error of getErrors('email')"
              :key="error.$uid"
            >
              <p class="error-block">
                {{ error.$message }}
              </p>
              <p v-if="!v$.$errors.length" />
            </div>
          </div>
          <!-- <input
            v-else
            v-model="phone"
            class="data-in"
            type="text"
            placeholder="Телефон"
          /> -->
          <AuInput
            v-model="password"
            class="input-wrap"
            type="Password"
            :placeholder="$t(`login.password`)"
          />
          <div
            v-if="!isLogin"
            class="error-block-wrapper"
          >
            <div
              v-for="error of getErrors('password')"
              :key="error.$uid"
            >
              <p class="error-block">
                {{ error.$message }}
              </p>
              <p v-if="!v$.$errors.length" />
            </div>
          </div>
          <label
            v-if="is2FaEnable"
            for="code"
            class="minimal"
          >{{ $t("login.googleCode") }}</label>
          <input
            v-if="is2FaEnable"
            id="code"
            v-model="token"
            class="data-in input-wrap"
            type="text"
            :placeholder="$t(`login.googleCode`)"
            @input="input2fa"
          >
          <AuInput
            v-if="!isLogin"
            v-model="passwordConfirm"
            class="input-wrap"
            type="Password"
            :placeholder="$t(`login.passwordRepeat`)"
          />
          <div
            v-if="!isLogin"
            class="error-block-wrapper"
          >
            <div
              v-for="error of getErrors('passwordConfirm')"
              :key="error.$uid"
            >
              <p class="error-block">
                {{ error.$message }}
              </p>
              <p v-if="!v$.$errors.length" />
            </div>
          </div>
          <input
            v-if="!isLogin"
            v-model="fName"
            class="data-in input-wrap"
            type="text"
            :placeholder="$t(`login.fName`)"
          >
          <div
            v-if="!isLogin"
            class="error-block-wrapper"
          >
            <div
              v-for="error of getErrors('fName')"
              :key="error.$uid"
            >
              <p class="error-block">
                {{ error.$message }}
              </p>
              <p v-if="!v$.$errors.length" />
            </div>
          </div>
          <input
            v-if="!isLogin"
            v-model="iName"
            class="data-in input-wrap"
            type="text"
            :placeholder="$t(`login.sName`)"
          >
          <div
            v-if="!isLogin"
            class="error-block-wrapper"
          >
            <div
              v-for="error of getErrors('iName')"
              :key="error.$uid"
            >
              <p class="error-block">
                {{ error.$message }}
              </p>
              <p v-if="!v$.$errors.length" />
            </div>
          </div>
          <div
            v-if="!isLogin"
            class="captcha-wrap input-wrap"
          >
            <img
              v-if="captcha"
              :src="captcha.url"
              alt="captcha"
              class="captcha"
            >

            <div
              v-else
              class="skeleton"
            />

            <input
              v-if="!isLogin"
              v-model="captchaValue"
              class="data-in"
              type="text"
              placeholder="Captcha *"
            >

            <AuIcon
              icon="update"
              class="update-icon"
              clickable
              @click="updateCaptcha"
            />
          </div>
          <div
            v-if="!isLogin"
            class="error-block-wrapper"
          >
            <div
              v-for="error of getErrors('captchaValue')"
              :key="error.$uid"
            >
              <p class="error-block">
                {{ error.$message }}
              </p>
              <p v-if="!v$.$errors.length" />
            </div>
          </div>
          <AuError
            v-if="isLogin"
            :error-msg="errorMsg"
          />
          <button
            :disabled="progress"
            class="enter-button"
            @click="loginOrRegister"
          >
            <img
              v-if="progress"
              src="/svg/dark/progress_rolling_green.svg"
              alt="progress"
            >
            {{ buttonText }}
          </button>
        </div>
        <div
          v-if="isLogin"
          class="text-remember"
          @click="goToRestore"
        >
          {{ $t("login.passwordNotRemember") }}
        </div>
      </div>
    </div>
    <Footer class="footer" />
  </div>
</template>

<script>
import _ from "lodash";

import Footer from "@/panels/Footer.vue";
import localStorageController from "@/help/localStorageController";
import SocialNetwork from "@/components/SocialNetwork";

import { containsLowercase,
  containsNumber,
  containsUppercase,
  email,
  minLength,
  requiredCaptcha,
  requiredEmail,
  requiredFName,
  requiredIName,
  requiredPassword,
  sameAsPassword
} from "@/localization/localized-validators";
import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";

export default {
  name: "Login",
  components: {
    Footer,
    SocialNetwork
  },
  data() {
    return {
      v$: useVuelidate(),
      showLaptop: false,
      progress: false,
      passwordMismatch: "",
      isLogin: true,
      isEmail: true,
      email: "",
      phone: "",
      password: "",
      passwordConfirm: "",
      fName: "",
      iName: "",
      oName: "",
      userRef: null,
      refSource: null,
      token: "",
      is2FaEnable: false,
      errorVisible: false,
      errorMsg: "",
      captcha: null,
      captchaValue: "",
      isVerify: ""
    };
  },

  validations() {
    return {
      email: { requiredEmail, email },
      password: {
        requiredPassword,
        containsUppercase,
        containsLowercase,
        containsNumber,
        minLength: minLength(8)
      },
      passwordConfirm: {
        sameAsPassword: sameAsPassword(this.password)
      },
      fName: {
        requiredFName
      },
      iName: {
        requiredIName
      },
      captchaValue: {
        requiredCaptcha
      }
    };
  },

  computed: {
    ...mapGetters({
      isLightTheme: "isLightTheme",
      isTokenVerify: "isTokenVerify"
    }),

    mainLogo() {
      return process.env.VUE_APP_AUT_LOGO || "logo.v2";
    },
    socialBtn() {
      return process.env.VUE_APP_FACEBOOK_ENABLED === "true" || process.env.VUE_APP_GOOGLE_ENABLED === "true";
    },
    header1() {
      return process.env.VUE_APP_HEADER1 || "Real World Assets Tokenization Platform";
    },
    header2() {
      return process.env.VUE_APP_HEADER2 || "Autentic RWA";
    },
    header3() {
      return process.env.VUE_APP_HEADER3 || "Real-world assets (RWAs) in blockchain are digital tokens that represent physical and traditional financial assets, such as currencies, commodities, equities, and bonds.";
    },
    imgLaptop() {
      return process.env.VUE_APP_LAPTOP || "laptop";
    },

    isInvalid() {
      return this.v$.$errors.length > 0;
    },

    allField() {
      return (
        this.password !== null &&
          this.password.length >= 8 &&
          this.email !== null &&
          this.email.length >= 1 &&
          this.passwordConfirm !== null &&
          this.passwordConfirm.length >= 1 &&
          this.fName.length >= 1 &&
          this.iName.length >= 1
      );
    },

    isConfirm() {
      return this.password === this.passwordConfirm;
    },

    buttonText() {
      if (this.progress) {
        return "";
      }
      else {
        return this.isLogin ? `${this.$t("login.enter")}` : `${this.$t("login.register")}`;
      }
    }
  },

  watch: {
    password() {
      this.v$.password.$touch();
    },

    passwordComfirm() {
      this.v$.passwordComfirm.$touch();
    },

    email() {
      this.v$.email.$touch();
    },

    fName() {
      this.v$.fName.$touch();
    },

    iName() {
      this.v$.iName.$touch();
    },

    captchaValue() {
      this.v$.captchaValue.$touch();
    },

    async isLogin(value) {
      this.errorMsg = "";

      if (!value && !this.captcha) {
        await this.updateCaptcha();
      }
    }
  },

  mounted() {
    this.isVerify = this.isTokenVerify;
    this.$store.dispatch("setIsTokenVerify", "");

    this.userRef = this.$route.query.userRef || localStorageController.getUserRef() || null;
    this.refSource = this.$route.query.refSource || localStorageController.getRefSource() || null;

    if (this.userRef) {
      localStorageController.setUserRef(this.userRef);
    }
    if (this.refSource) {
      localStorageController.setRefSource(this.refSource);
    }

    if (this.$auth.user()) {
      this.$router.push("/");
    }

    this.isLogin = this.$route.name === "login";
  },

  methods: {
    ...mapActions({
      register: "register",
      getCaptcha: "getCaptcha"
    }),

    async updateCaptcha() {
      this.captchaValue = "";
      try {
        this.captcha = await this.getCaptcha();
      }
      catch (error) {
        console.error(error);
      }
    },

    getErrors(property) {
      return _.filter(this.v$.$errors, error => error.$propertyPath == property);
    },

    goToRestore() {
      this.$router.push({ name: "password restore", params: { hash: "restore" } });
    },

    changeTab() {
      this.isLogin = !this.isLogin;
      this.$router.push({ name: this.isLogin ? "login" : "sign" });
    },

    input2fa() {
      if (this.token.length >= 6) {
        this.progress = true;
        this.logIn();
      }
    },

    loginOrRegister() {
      this.progress = true;
      if (this.isLogin) {
        this.logIn();
        return;
      }
      this.regIn();
    },

    logIn() {
      const data = {
        email: this.isEmail ? this.email : this.phone,
        password: this.password
      };
      if (this.is2FaEnable) {
        if (this.token !== "") {
          data.authCode = this.token;
        }
        else {
          this.showError(this.$t("login.message.needAuthCode"));
        }
      }

      this.$auth
        .login({
          data,
          staySignedIn: true,
          fetchUser: false,
          url: "/api/users/login"
        })
        .then(res => {
          this.progress = false;
          if (res?.data?.errors) {
            if (res?.data?.errors == "2fa is enabled! Need authcode!") {
              this.is2FaEnable = true;
            }
            else {
              this.showError(res?.data?.errors);
            }
          }
          else {
            try {
              if (window.carrotquest && process.env.VUE_APP_CARROT_ENABLED === "true") {
                window.carrotquest.track("$authorized", { "$email": this.email });
                window.carrotquest.identify([
                  { op: "update_or_create", key: "$email", value: this.email }
                ]);
              }
            }
            catch (error) {
              console.error(error);
            }
          }
          if (this.$route.redirectedFrom?.query?.outer == "true") {
            this.$router.push({ path: this.$route.redirectedFrom?.fullPath });
          }
          else {
            this.$router.push({ name: "dashboard" });
          }
        }, e => {
          this.showError(e?.response.data?.errors);
        });

      this.$auth.remember(data.email);
    },

    async regIn() {
      this.v$.$touch();

      if (this.isInvalid) {
        this.showError(this.v$.$errors[0]?.$message);
        this.progress = false;
        return;
      }
      const form_data = {
        email: this.isEmail ? this.email : this.phone,
        password: this.password,
        fName: this.fName,
        iName: this.iName,
        oName: this.oName,
        userRef: this.userRef,
        refSource: this.refSource,
        captchaUID: this.captcha.uid,
        captchaPhrase: this.captchaValue
      };

      try {
        const resp = await this.register(form_data);

        if (window.carrotquest && process.env.VUE_APP_CARROT_ENABLED === "true") {
          window.carrotquest.track("$registered", { "$email": this.email });
          window.carrotquest.identify([
            { op: "update_or_create", key: "$email", value: this.email }
          ]);
        }

        if (resp.status === 267) {
          this.progress = false;
          this.showError(resp.data.error);

          if (resp.data.error == "Captcha expired!") {
            this.updateCaptcha();
            this.captchaValue = "";
          }
        }

        if (resp.status === 202) {
          this.progress = false;
          this.showSuccess();
          this.logIn();
        }
      }
      catch (error) {
        this.progress = false;
        if (error.response.status === 400) {
          this.showError(this.$t("login.message.mailExist"));
        }
        else if (error.response.status === 422) {
          this.showError(this.$t("login.message.errorContent"));
        }
        else if (error.response.status === 403) {
          this.showError(error.response.data.error);
        }
      }
    },

    showSuccess() {
      this.$toast.success(
        this.$t("login.message.registerSuccess"),
        {
          duration: 8000
        }
      );
    },

    showError(message) {
      if (!this.errorVisible) {
        this.errorVisible = true;
        this.errorMsg = message;
        this.$toast.error(message, {
          duration: 8000
        });
        setTimeout(() => this.errorVisible = false, 8000);
      }
    }

    // startPixelStatistics() {
    //   let _tmr = window._tmr || (window._tmr = []);
    //   _tmr.push({ id: "3336008", type: "pageView", start: (new Date()).getTime() });
    //   (function (d, w, id) {
    //     if (d.getElementById(id)) {
    //       return;
    //     }
    //     let ts = d.createElement("script");
    //     ts.type = "text/javascript";
    //     ts.async = true;
    //     ts.id = id;
    //     ts.src = "https://top-fwz1.mail.ru/js/code.js";
    //     let f = function () {
    //       let s = d.getElementsByTagName("script")[0];
    //       s.parentNode.insertBefore(ts, s);
    //     };
    //     if (w.opera == "[object Opera]") {
    //       d.addEventListener("DOMContentLoaded", f, false);
    //     }
    //     else {
    //       f();
    //     }
    //   })(document, window, "tmr-code");
    // }
  }
};
</script>

<style scoped lang="scss">
.user-view {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--main-color);
  height: 100vh;
  width: 100vw;
  overflow: auto;
}

.form-and-image {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 200px;
  margin-top: auto;
  margin-bottom: auto;

  @media screen and (max-width: 1200px) {
    margin-top: 25px;
    gap: 45px;
    flex-direction: column-reverse;
  }
}
.left-block {
  display: flex;
  flex-direction: column;
  gap: 37px;
}
.title {
  display: flex;
  flex-direction: column;
  margin-left: 74px;
  width: 555px;

  @media screen and (max-width: 600px) {
    width: auto;
    margin-left: 0;
    padding: 0 25px;
  }

  .header1 {
    font-weight: 500;
    font-size: 18px;

    @media screen and (max-width: 600px) {
      font-size: 15px;
    }
  }

  .header2 {
    font-weight: 700;
    font-size: 64px;

    @media screen and (max-width: 600px) {
      font-size: 50px;
    }
  }

  .header3 {
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;

    @media screen and (max-width: 600px) {
      font-size: 16px;
      line-height: 25px;
    }
  }
}
.laptop {
  display: flex;
  align-items: center;
  position: relative;
}
.laptop-img {
  z-index: 100;
  width: 569px;
  height: 341px;
  @media screen and (max-width: 600px) {
    width: 90vw;
    height: auto;
  }
}
.green-place {
  display: flex;
  position: absolute;
  left: 157px;

  @media screen and (max-width: 1200px) {
    left: 0;
  }
}
.rectangle {
  height: 251px;
  background-color: #28E593;
}
.line1 {
  width: 469px;
  margin-right: 14px;
  @media screen and (max-width: 500px) {
    width: 350px;
  }
  @media screen and (max-width: 400px) {
    width: 280px;
  }
}
.line2 {
  width: 14px;
  margin-right: 20px;
}
.line3 {
  width: 7px;
  margin-right: 29px;
}
.line4 {
  width: 2px;
  margin-right: 33px;
}
.line5 {
  width: 1px;
}
.footer {
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.panel {
  display: flex;
  flex-direction: column;
  width: 350px;
  border: 1px solid var(--color-90);
  border-radius: 20px;
  padding: 20px;
  background: var(--card-color);
}

.select {
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  border: 1px solid lightgray;
  height: 55px;
  margin-top: 30px;
  margin-bottom: 26px;
}

.tab-select {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  cursor: pointer;
}

.checked {
  border-radius: 10px;
  background: var(--color-80);
}

.data-in {
  border-radius: 10px;
  border: 1px solid var(--color-70);
  padding: 15px;
  margin-bottom: 5px;

  &::placeholder {
    color: var(--color-50);
  }
}

.input-wrap {
  margin-bottom: 5px;
  margin-top: 10px;
}

.error-block {
  display: flex;
  font-weight: 400;
  font-size: 10px;
  margin: 0 auto;
  color: red;
}
.error-verify {
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  margin-top: -10px;
  margin-bottom: 12px;
  color: red;
}
.success-verify {
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  margin-top: -10px;
  margin-bottom: 12px;
  color: green;
}

.text-explain {
  color: grey;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
}

.text-remember {
  color: grey;
  font-weight: 450;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  margin-top: 20px;
  text-decoration: underline;
}

.enter-button {
  background: #2aba7c;
  border-radius: 8px;
  color: white;
  height: 44px;
  margin-top: 20px;
  border: 1px solid #2aba7c;
  &:hover {
    background: #8adcba;
  }
}

.logo-scale {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.img-scale {
  width: 400px;

  @media screen and (max-width: 990px) {
    width: 300px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.radio {
  font-size: 13px;
  margin-right: 20px;
}

button:disabled {
  background: #FFFFFF33;
}

.minimal {
  color: grey;
  font-size: 12px;
  margin-bottom: 5px;
}

.captcha-wrap {
  display: flex;
  gap: 10px;
  align-items: center;

  .captcha {
    border-radius: 10px;
    height: 51.5px;
    border: 1px solid var(--color-70);
  }

  &>input {
    margin: 0;
    width: 100%;
  }

  .update-icon:not(:active) {
    transition: 0.5s ease rotate;
    rotate: 360deg;
  }
}

@keyframes bgAnimate {
  0% {
    background-position: 50% 0;
  }

  100% {
    background-position: -150% 0;
  }
}

.skeleton {
  height: 51.5px;
  width: 100%;

  border-radius: 10px;

  padding: 15px;

  user-select: none;

  background: var(--card-color);
  background-size: 200% 100%;

  animation: bgAnimate 2s linear infinite;

  background-image: linear-gradient(
    to right,
    var(--color-90) 0%,
    var(--color-95) 10%,
    var(--color-95) 20%,
    var(--color-90) 100%
  );
}

.top {
  margin-top: 32px;
}
.plug {
  width: 494px;
  height: 341px;
  background-color: #cccccc;
  opacity: 0.5;
  z-index: 100;

  position: relative;
  left: 75px;

  @media screen and (max-width: 600px){
    width: 100%;
    left: 0;
    margin: 0 25px;
  }
}
</style>
