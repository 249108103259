<template>
  <div>
    <div class="d-xl-flex filter-line">
      <div
        class="col-xl d-flex order-xl-1 justify-content-between justify-content-xl-end search"
      >
        <div class="mb-s-2">
          <AuInput
            v-model="search"
            icon="searchc"
            border-radius="22"
            :placeholder="$t('auCapitals.projects.placeholderSearch')"
          />
        </div>
      </div>
      <div class="col-xl d-flex switches">
        <AuSwitchButton
          :active="showFavorites"
          width="auto"
          border-radius="22"
          :label="$t('transactions.allPlaceholder')"
          @click="changeShowFavorites()"
        >
          <span
            v-if="showFavorites"
            class="following"
          >
            <AuIcon
              icon="star-following"
              @click="changeShowFavorites()"
            />  {{ $t("auCapitals.projects.showFavorites") }}
          </span>

          <span
            v-else
            class="following"
          >
            <AuIcon
              icon="star"
              @click="changeShowFavorites()"
            />  {{ $t("auCapitals.projects.showAll") }}
          </span>
        </AuSwitchButton>

        <AuSelect
          :value="status"
          :options="typeOptions"
          :placeholder="$t('auCapitals.projects.placeholderFilter')"
          border-radius="22"
          @input="value => status = value"
        />

        <AuSelect
          class="selector"
          :value="selectedCategoryId"
          :placeholder="$t('auCapitals.projects.placeholderCategory')"
          :options="sectionCapitalOptions"
          border-radius="22"
          @input="(value) => (selectedCategoryId = value)"
        />
      </div>
    </div>

    <ProgressLoader
      v-if="loadingFilters"
      mode="indeterminate"
      color="#5AC396"
      size="150px"
    />
    <ProgressLoader
      v-if="loading && !projects.length"
      mode="indeterminate"
      color="#5AC396"
      size="150px"
    />

    <div
      v-if="!loadingFilters"
      class="projects"
    >
      <CapitalCard
        v-for="proj in projects"
        :key="proj.id"
        :project="proj"
      />
    </div>

    <div
      class="load-more"
    >
      <AuButton
        v-if="currentTake < totalItems && !loadingFilters"
        type="primary"
        center
        width="125px"
        :is-progress="loading"
        @click="getMore"
      >
        {{ $t("auCapitals.projects.loadMore") }}
      </AuButton>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

import CapitalCard from "@/components/capital/CapitalCard";
import ProgressLoader from "@/loaders/progress-bar";
import { convertCapital } from "@/components/capital/capitalHelper";

export default {
  name: "Projects",
  components: {
    CapitalCard,
    ProgressLoader
  },
  data() {
    return {
      search: null,
      projects: [],
      filtersFlag: {},
      selectedCategoryId: null,
      auCapitals: [],
      page: 1,
      totalItems: 1,
      loading: true,
      loadingFilters: false,
      status: "All",
      showFavorites: false,
      currentTake: 32,
      take: 32
    };
  },

  computed: {
    ...mapGetters({
      sectionCapital: "sectionCapital",
      searchedAuCapitals: "capitals/searchedAuCapitals",
      companies: "companies",
      followings: "capitals/followings"
    }),

    typeOptions() {
      return [
        { display: this.$t("auCapitals.projects.typeAll"), value: "All" },
        { display: "Open", value: "Open" },
        { display: "Waiting", value: "Waiting" },
        { display: "Closed", value: "Closed" },
        { display: "Completed", value: "Completed" },
        { display: "Declined", value: "Declined" }
      ];
    },

    sectionCapitalOptions() {
      const arrList = _.map(this.sectionCapital, (capital) => ({
        display: capital.name,
        value: capital.id
      }));

      arrList.unshift({
        display: "all",
        value: -1
      });

      return arrList;
    }
  },

  watch: {
    search: _.debounce(async function () {
      this.loadingFilters = true;
      await this.getCapital();
      this.loadingFilters = false;
    }, 500),

    async status() {
      this.loadingFilters = true;
      await this.getCapital();
      this.loadingFilters = false;
    },

    async selectedCategoryId() {
      this.loadingFilters = true;
      await this.getCapital();
      this.loadingFilters = false;
    }
  },

  async mounted() {
    await this.getSectionCapital();
    await this.getCapital();
    await this.getFollowings();
    this.loading = false;
    for (let i = 0; i < this.sectionCapital; ++i) {
      let item = {};
      item[this.sectionCapital[i].id] = false;
      this.filtersFlag.push(item);
    }
  },

  methods: {
    ...mapActions({
      getSectionCapital: "getSectionCapital",
      getAllAuCapitalsPagination: "capitals/getAllAuCapitalsPagination",
      getFollowings: "capitals/getFollowings",
      sendCapital: "sendCapital"
    }),

    async getMore() {
      this.loading = true;
      this.currentTake += this.take;
      await this.getCapital();
      this.loading = false;

      const loadMore = document.querySelector(".main-panel");
      const projects = document.querySelector(".projects");

      loadMore.scrollTop = projects.clientHeight;
    },

    async changeShowFavorites() {
      this.loading = true;
      this.showFavorites = !this.showFavorites;
      await this.getCapital();
      this.loading = false;
    },

    async pageChanged(actualPage) {
      this.page = actualPage;
      await this.getCapital();
    },

    async getCapital() {
      try {
        let statusFilter = this.status.toLowerCase();

        if (this.status == "All") {
          statusFilter = null;
        }
        else if (this.status == "Declined") {
          statusFilter = "not_success";
        }
        else if (this.status == "Completed") {
          statusFilter = "success";
        }

        let resp = await this.getAllAuCapitalsPagination({
          page: 1,
          itemsPerPage: this.currentTake,
          order: "desc",
          isFollowed: this.showFavorites ? this.showFavorites : null,
          description: this.search,
          status: statusFilter,
          sectionId: this.selectedCategoryId === -1 ? null : this.selectedCategoryId
        });
        this.auCapitals = resp.data;
        this.totalItems = resp.totalItems;

        const capitalData = this.auCapitals;
        this.projects = await this.convertAuCapitals(capitalData);
      }
      catch (e) {
        console.log("error", e);
      }
    },

    selectedSearched() {
      const set = new Set();
      for (const { id } of this.searchedAuCapitals) {
        set.add(id);
      }

      const intersection = this.projects.filter(({ id }) => set.has(id));
      return intersection;
    },

    async convertAuCapitals(auCapitals) {
      let projects = [];
      for (const item of auCapitals) {
        const project = convertCapital(item, this.sectionCapital);
        projects.push(project);
      }

      return projects;
    }
  }
};
</script>

<style lang="scss" scoped>
.switches {
  gap: 10px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}
.search {
  margin-bottom: 10px;
}

.filter-line {
  margin-top: 25px;
  margin-bottom: 15px;

  @media screen and (max-width:1199px) {
    margin-bottom: 25px;
  }
}

.projects {
  display: flex;
  gap: 25px;
  flex-wrap: wrap;
}

.selector {
  min-width: 220px;
}

.following {
  display: flex;
  gap: 5px;
}

.load-more{
  display: flex;
  justify-content: center;

  >button {
    padding: 20px;
    margin-top: 40px;
  }
}
</style>
