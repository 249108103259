<template>
  <div
    v-if="!isIssuer"
    class="dashboard"
  >
    <ExchangeRates />
    <DashboardProjects is-investment />
    <DashboardProjects />
    <LastTransactions />
  </div>
</template>

<script>
import _ from "lodash";
import DashboardProjects from "@/components/DashboardProjects";
import ExchangeRates from "../components/exchangeRates/top/ExchangeRates.vue";
import LastTransactions from "@/components/lastTransactions/LastTransactions";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    LastTransactions,
    ExchangeRates,
    DashboardProjects
  },

  computed: {
    ...mapGetters({
      isTokenVerify: "isTokenVerify",
      profile: "profile/profile"
    }),

    pages() {
      let parent = _.find(this.$route.matched, (match) => match.meta.isMain);
      if (parent) {
        return _.filter(
          parent.children,
          (child) => _.get(child, "meta.isHelp") == true
        );
      }
      else {
        return [];
      }
    },

    isIssuer() {
      return this.$auth.user().type == "issuer";
    }
  },

  async mounted() {
    this.verifyInfo();
    if (this.isIssuer) {
      this.$router.push({ name: "issuer" });
    }
    else {
      await this.getFollowings();
    }
  },

  methods: {
    ...mapActions({
      getFollowings: "capitals/getFollowings"
    }),

    setRoute(page) {
      this.$router.push({ name: page.name });
    },

    verifyInfo() {
      if (this.isTokenVerify == "success") {
        this.$toast.success("Email successfully confirmed!");
      }
      if (this.isTokenVerify == "error") {
        if (this.profile.isConfirmed) {
          this.$toast.success("Email already confirmed!");
        }
        else {
          this.$toast.error("Invalid or outdated confirmation link!");
        }
      }
      this.$store.dispatch("setIsTokenVerify", "");
    }
  }
};
</script>

<style scoped lang="scss">
.dashboard {
  width: 100%;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: space-between;
}
</style>
