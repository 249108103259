<template>
  <div>
    <AuBreadcrumb />
    <div class="card dao">
      <div class="d-flex justify-content-between">
        <span class="title">
          {{ $t("daoPage.title") }}
        </span>
        <AuButton
          border-radius="10"
          bordered
          center
          width="150px"
          @click="showAdd"
        >
          {{ $t("daoPage.button") }}
        </AuButton>
      </div>
      <AuTable
        :columns="columns"
        :items="requestsItems"
        :loading="dataLoading"
      >
        <template #action="{value}">
          <div
            v-click-outside="closeAllMenus"
            class="container-menu"
          >
            <AuIcon
              icon="more"
              clickable
              @click="openMenu(value.index)"
            />
            <div
              v-if="showMenu[value.index]"
              class="context-menu"
            >
              <AuButton
                width="100%"
                center
                @click="showEdit(value)"
              >
                {{ $t("daoPage.menuEdit") }}
              </AuButton>
              <AuButton
                width="100%"
                center
                @click="showDelete(value)"
              >
                {{ $t("daoPage.menuDelete") }}
              </AuButton>
              <AuButton
                width="100%"
                center
                @click="goToResult(value.id)"
              >
                {{ $t("daoPage.menuResult") }}
              </AuButton>
              <AuButton
                width="100%"
                center
                @click="showApprove(value)"
              >
                {{ $t("daoPage.menuApprove") }}
              </AuButton>
            </div>
          </div>
        </template>
      </AuTable>
      <AuPagination
        v-if="daoRequestsTotal > itemsPerPage"
        :total-items="daoRequestsTotal"
        @pageChanged="changePage"
      />
    </div>
    <AuModal
      v-model="showDialog"
      width="490px"
    >
      <template #header>
        <span class="modal_title">
          {{ action }}
        </span>
      </template>
      <template #body>
        <div v-if="mode !== 'delete' && mode !== 'approve'">
          <div class="d-flex">
            <div
              class="modal_switch me-2"
              :class="{'modal_checked': isVoting}"
              @click="isVoting=true"
            >
              {{ $t("daoPage.tabVote") }}
            </div>
            <div
              class="modal_switch"
              :class="{'modal_checked': !isVoting}"
              @click="isVoting=false"
            >
              {{ $t("daoPage.tabAnswer") }}
            </div>
          </div>
          <div
            v-if="isVoting"
            class="modal_border"
          >
            <div>{{ $t("daoPage.modalName") }}</div>
            <AuInput
              v-model="name"
              type="Text"
            />
            <div>{{ $t("daoPage.modalDescription") }}</div>
            <AuInput
              v-model="description"
              text-area-heigth="100"
              type="TextArea"
            />
            <div>{{ $t("daoPage.modalLimit") }}</div>
            <AuInput
              v-model="tokenLimit"
              type="Number"
            />
            <div>{{ $t("daoPage.modalStart") }}</div>
            <AuInput
              v-model="startAt"
              type="Date"
              :min-date="new Date(new Date().getTime() + (24 * 60 * 60 * 1000))"
              :format="formatView"
              clearable
            />
            <div>{{ $t("daoPage.modalEnd") }}</div>
            <AuInput
              v-model="endAt"
              type="Date"
              :min-date="new Date(new Date().getTime() + (24 * 60 * 60 * 1000))"
              :format="formatView"
              clearable
            />
            <div class="d-flex align-items-center mt-2">
              <input
                v-model="useTokens"
                type="checkbox"
              >
              <span class="ms-1">{{ $t("daoPage.modalUseToken") }}</span>
            </div>
            <div
              v-if="useTokens"
              class="mt-2"
            >
              <AuSelect
                :value="selectedToken"
                :options="tokensItems"
                :placeholder="$t('daoPage.modalSelectTokenPlaceholder')"
                @input="v => selectedToken = v"
              />
            </div>
          </div>
          <div
            v-else
            class="modal_border"
          >
            <div
              v-for="(e, index) of elements"
              :key="index"
            >
              <div>{{ $t("daoPage.modalAnswer") }}</div>
              <div class="modal_gap">
                <AuInput
                  v-model="elements[index].name"
                  type="Text"
                />
                <AuButton
                  bordered
                  border-radius="10"
                  center
                  @click="deleteElement(index)"
                >
                  -
                </AuButton>
                <AuButton
                  bordered
                  border-radius="10"
                  center
                  @click="elements.push({name: null})"
                >
                  +
                </AuButton>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <span>{{ mode === 'delete' ? $t("daoPage.modalIsDelete") : $t("daoPage.modalApprove") }}</span>
        </div>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end mt-3">
          <AuButton
            class="me-3"
            border-radius="10"
            bordered
            center
            width="150px"
            @click="doAction"
          >
            {{ action }}
          </AuButton>
          <AuButton
            border-radius="10"
            bordered
            center
            width="150px"
            @click="showDialog=false"
          >
            {{ $t("daoPage.buttonCancel") }}
          </AuButton>
        </div>
      </template>
    </AuModal>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "DAORequests",
  props: {
    id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      showMenu: [],
      columns: [
        { name: this.$t("daoPage.firstColumn"), align: "start" },
        { name: this.$t("daoPage.secondColumn"), align: "start" },
        { name: this.$t("daoPage.thirdColumn"), align: "start" },
        { name: this.$t("daoPage.fourthColumn"), align: "start" },
        { name: this.$t("daoPage.fifthColumn"), align: "center" },
        { name: this.$t("daoPage.sixColumn"), align: "center", slot: "action" }
      ],
      statuses: {
        draft: this.$t("daoPage.statusDraft"),
        prepared: this.$t("daoPage.statusPrepared"),
        ready: this.$t("daoPage.statusReady"),
        process: this.$t("daoPage.statusProcess"),
        closed: this.$t("daoPage.statusClosed")
      },
      page: 1,
      itemsPerPage: 30,
      showDialog: false,
      isVoting: true,
      name: null,
      description: null,
      tokenLimit: 0,
      startAt: null,
      endAt: null,
      useTokens: null,
      selectedToken: null,
      elements: [{ name: null }],
      modifyId: null,
      mode: null
    };
  },

  computed: {
    ...mapGetters({
      daoRequests: "dao/daoRequests",
      daoRequestsTotal: "dao/daoRequestsTotal",
      daoElements: "dao/daoElements",
      dataLoading: "dao/dataLoading",
      voitingTokens: "dao/voitingTokens"
    }),

    requestsItems() {
      return _.map(this.daoRequests, (item, index) => {
        return {
          name: item.name,
          tokenLimit: item.tokenLimit,
          startAt: moment(item.startAt).format("DD.MM.YYYY"),
          endAt: moment(item.endAt).format("DD.MM.YYYY"),
          status: _.get(this.statuses, item.status, "-"),
          action: {
            id: item.id,
            index: index,
            name: item.name,
            description: item.description,
            tokenLimit: item.tokenLimit,
            startAt: item.startAt,
            endAt: item.endAt,
            status: item.status,
            tokenDao: item?.tokenDao ?? null
          }
        };
      });
    },

    action() {
      let ret = null;
      switch (this.mode) {
        case "add": ret = this.$t("daoPage.actionAdd"); break;
        case "edit": ret = this.$t("daoPage.actionEdit"); break;
        case "delete": ret = this.$t("daoPage.actionDelete"); break;
        case "approve": ret = this.$t("daoPage.actionApprove"); break;
      }
      return ret;
    },

    tokensItems() {
      return _.map(this.voitingTokens, item => ({
        display: item.nameEng,
        value: `/api/au_tokens/${item.id}`
      }));
    }
  },

  async mounted() {
    await this.getDao();
    await this.getVoitingTokens();
    this.closeAllMenus();
  },

  methods: {
    ...mapActions({
      getDaoRequests: "dao/getDaoRequests",
      getDaoElements: "dao/getDaoElements",
      addDaoRequest: "dao/addDaoRequest",
      editDaoRequest: "dao/editDaoRequest",
      deleteDaoRequest: "dao/deleteDaoRequest",
      addDaoElement: "dao/addDaoElement",
      editDaoElement: "dao/editDaoElement",
      deleteDaoElement: "dao/deleteDaoElement",
      getVoitingTokens: "dao/getVoitingTokens"
    }),

    async getDao() {
      await this.getDaoRequests({
        page: this.page,
        itemsPerPage: this.itemsPerPage
      });
    },

    formatView(date) {
      return moment(date).locale("ru").format("L");
    },

    closeAllMenus() {
      this.showMenu = _.map(this.daoRequests, () => false);
    },

    openMenu(index) {
      this.closeAllMenus();
      this.showMenu[index] = true;
    },

    async changePage(page) {
      this.page = page;
      await this.getDao();
    },

    deleteElement(index) {
      if (this.elements.length > 1) {
        this.elements.splice(index, 1);
      }
    },

    showSuccess(message) {
      this.$toast.success(message);
    },

    showError(message) {
      this.$toast.error(message);
    },

    async addElements(requestId) {
      for (let i = 0; i < this.elements.length; i++) {
        const body = {
          name: this.elements[i].name,
          request: `/api/dao_requests/${requestId}`
        };
        await this.addDaoElement(body);
      }
    },

    async addRequest() {
      const body = {
        name: this.name,
        description: this.description,
        owner: `/api/users/${this.$auth.user().id}`,
        createdAt: moment().format("DD.MM.YYYY HH:mm:ss"),
        tokenLimit: Number(this.tokenLimit),
        startAt: this.startAt,
        endAt: this.endAt
      };
      if (this.useTokens) {
        body.tokenDao = this.selectedToken;
      }
      try {
        await this.addDaoRequest(body);
        await this.getDao();
        await this.addElements(this.daoRequests[0].id);
        this.showSuccess(this.$t("daoPage.success.add"));
      }
      catch (e) {
        this.showError(this.$t("daoPage.error.add"));
      }
    },

    doAction() {
      this.showDialog = false;
      switch (this.mode) {
        case "add": this.addRequest(); break;
        case "edit": this.editRequest(); break;
        case "delete": this.deleteRequest(); break;
        case "approve": this.approve(); break;
      }
    },

    showAdd() {
      this.mode = "add";
      this.modifyId = null;
      this.name = null;
      this.description = null;
      this.tokenLimit = null;
      this.startAt = null;
      this.endAt = null;
      this.useTokens = false;
      this.selectedToken = null;
      this.elements = [{ name: null }];
      this.showDialog = true;
    },

    async showEdit(value) {
      if (value.status === "process" || value.status === "closed") {
        const prefix = value.status === "process" ?
          this.$t("daoPage.error.processed") : this.$t("daoPage.error.closed");
        this.showError(`${prefix} ${this.$t("daoPage.error.editEmbargo")}`);
        return;
      }
      this.mode = "edit";
      this.modifyId = value.id;
      this.name = value.name;
      this.description = value.description;
      this.tokenLimit = value.tokenLimit.toString(10);
      this.startAt = value.startAt;
      this.endAt = value.endAt;
      this.useTokens = value.tokenDao !== null;
      this.selectedToken = value.tokenDao;
      await this.getDaoElements(value.id);
      this.elements = _.map(this.daoElements, item => ({ id: item.id, name: item.name }));
      if (_.isEmpty(this.elements)) {
        this.elements = [{ name: null }];
      }
      this.showDialog = true;
    },

    async showDelete(value) {
      this.mode = "delete";
      this.modifyId = value.id;
      await this.getDaoElements(value.id);
      this.elements = _.map(this.daoElements, item => ({ id: item.id, name: item.name }));
      this.showDialog = true;
    },

    async showApprove(value) {
      this.mode = "approve";
      this.modifyId = value.id;
      this.showDialog = true;
    },

    async changeElements(requestId) {
      for (const item of this.daoElements) {
        const index = _.findIndex(this.elements, el => el.id == item.id);
        if (index !== -1) {
          if (this.elements[index].name !== item.name) {
            await this.editDaoElement({
              id: item.id,
              body: {
                name: this.elements[index].name,
                request: `/api/dao_requests/${requestId}`
              }
            });
          }
          this.elements.splice(index, 1);
        }
        else {
          await this.deleteDaoElement(item.id);
        }
      }
      for (const item of this.elements) {
        await this.addDaoElement({
          name: item.name,
          request: `/api/dao_requests/${requestId}`
        });
      }
    },

    async editRequest() {
      const body = {
        name: this.name,
        description: this.description,
        owner: `/api/users/${this.$auth.user().id}`,
        tokenLimit: Number(this.tokenLimit),
        startAt: this.startAt,
        endAt: this.endAt
      };
      if (this.useTokens) {
        body.tokenDao = this.selectedToken;
      }
      try {
        await this.editDaoRequest({ id: this.modifyId, body: body });
        await this.changeElements(this.modifyId);
        await this.getDao();
        this.showSuccess(this.$t("daoPage.success.edit"));
      }
      catch (e) {
        this.showError(this.$t("daoPage.error.edit"));
      }
    },

    async deleteRequest() {
      try {
        for (const item of this.elements) {
          await this.deleteDaoElement(item.id);
        }
        await this.deleteDaoRequest(this.modifyId);
        await this.getDao();
        this.showSuccess(this.$t("daoPage.success.delete"));
      }
      catch (e) {
        this.showError(this.$t("daoPage.error.delete"));
      }
    },

    goToResult(id) {
      this.$router.push({ name: "dao events", params: { id: id } });
    },

    async approve() {
      const body = {
        status: "prepared"
      };
      try {
        await this.editDaoRequest({ id: this.modifyId, body: body });
        await this.getDao();
        this.showSuccess(this.$t("daoPage.success.approve"));
      }
      catch (e) {
        this.showError(this.$t("daoPage.error.approve"));
      }
    }
  }
};
</script>

<style scoped lang="scss">
.dao{
  padding: 20px;
  .title{
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 20px;
  }
}
.modal_title {
  font-weight: 450;
  font-size: 20px;
  margin-bottom: 20px;
}
.modal_switch{
  padding: 10px;
  border: 1px solid var(--scrollbar-color);
  border-radius: 10px;
  background: var(--color-70);
  cursor: pointer;
}
.modal_checked{
  background: var(--color-50);
}
.modal_border{
  border: 1px solid var(--scrollbar-color);
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
  height: 390px;
  overflow-y: scroll;
}
.modal_gap{
  display: flex;
  gap: 5px;
}
.container-menu{
  position: relative;
}
.context-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 128px;
  right: calc(50% - 128px / 2);
  background: var(--main-color);
  z-index: 10;
  top: 30px;
}
</style>