<template>
  <div>
    <AuBreadcrumb />

    <ProjectFormFirstStep
      v-if="currentStep == 1"
      :id="id"
      :first-part-of-project-info="firstPartOfProject"
      @nextStep="currentStep++"
      @firstPartOfProject="addFirstPartOfProject"
    />
    <ProjectFormSecondStep
      v-if="currentStep == 2"
      :id="id"
      :show-price-redeem="showPriceRedeem"
      :tokens-count="tokensCount"
      :second-part-of-project-info="secondPartOfProject"
      @second-part-of-project-info="addSecondPartOfProject"
      @prevStep="currentStep--"
      @nextStep="currentStep++"
    />
    <ProjectFormThirdStep
      v-if="currentStep == 3"
      :id="id"
      :loading="loading"
      :third-part-of-project-info="thirdPartOfProject"
      @third-part-of-project-info="addThirdPartOfProject"
      @prevStep="currentStep--"
      @send="doAction"
    />
  </div>
</template>

<script>
import _ from "lodash";

import ProjectFormFirstStep from "@/components/issuerForm/ProjectFormFirstStep.vue";
import ProjectFormSecondStep from "@/components/issuerForm/ProjectFormSecondStep.vue";
import ProjectFormThirdStep from "@/components/issuerForm/ProjectFormThirdStep";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ProjectFormThirdStep,
    ProjectFormFirstStep,
    ProjectFormSecondStep
  },

  props: {
    id: {
      type: String,
      default: "new"
    }
  },

  data() {
    return {
      currentStep: 1,
      firstPartOfProject: null,
      secondPartOfProject: null,
      thirdPartOfProject: null,
      index: null,
      loading: false
    };
  },

  computed: {
    ...mapGetters({
      tokens: "tokens",
      ethAddresses: "ethAddresses",
      requestsToIssue: "requestsToIssue"
    }),
    tokensCount() {
      return this.firstPartOfProject ? this.firstPartOfProject.tokensCount : "0";
    },
    showPriceRedeem() {
      return this.firstPartOfProject.investType == "redeemable";
    }
  },

  async mounted() {
    if (_.isEmpty(this.requestsToIssue)) {
      await this.getRequestsToIssue();
    }
    if (this.id !== "new") {
      this.requestsToIssue.find((item, i) => {
        if (item.id == this.id) {
          this.index = i;
          return true;
        }
      });
    }
  },

  methods: {
    ...mapActions({
      getRequestsToIssue: "getRequestsToIssue",
      setRequestToIssue: "setRequestToIssue",
      putRequestToIssue: "putRequestToIssue",
      editRequestToIssue: "editRequestToIssue",
      uploadFile: "files/uploadFile",
      patchFile: "files/patchFile"
    }),
    addFirstPartOfProject(value) {
      this.firstPartOfProject = value;
    },
    addSecondPartOfProject(value) {
      this.secondPartOfProject = value;
    },
    addThirdPartOfProject(value) {
      this.thirdPartOfProject = value;
    },

    async doAction() {
      if (this.id === "new") {
        await this.sendForm();
      }
      else {
        await this.editForm();
      }
    },

    async sendForm() {
      this.loading = true;
      let requestToIssue = {
        user: `/api/users/${this.$auth.user().id}`,
        tokenNameEng: this.firstPartOfProject.projectNameEng,
        tokenMnemonicEng: this.firstPartOfProject.projectAbbrNameEng,
        descriptionCapitalRus: this.firstPartOfProject.projectDescriptionRus,
        descriptionCapitalEng: this.firstPartOfProject.projectDescriptionEng,
        tokenCount: this.firstPartOfProject.tokensCount,
        company: this.firstPartOfProject.company,
        projectType: this.firstPartOfProject.projectType,
        cryptoActiveType: this.firstPartOfProject.cryptoActiveType,
        dateStart: this.formatDate(this.secondPartOfProject.dateStart),
        dateEnd: this.formatDate(this.secondPartOfProject.dateEnd),
        descriptionToken: "-",
        minCount: this.secondPartOfProject.minCount,
        section: this.secondPartOfProject.section,
        priceBase: this.secondPartOfProject.priceBase,
        tokenQuote: this.secondPartOfProject.tokenQuote,
        quote: this.secondPartOfProject.quote,
        expectedProfit: this.secondPartOfProject.expectedProfit,
        minCap: this.secondPartOfProject.minCap,
        maxCap: this.secondPartOfProject.maxCap,
        logicType: "asset",
        isForCapitall: true,
        ethAddress: !_.isEmpty(this.ethAddresses) ? `/api/eth_addresses/${this.ethAddresses[0].id}` : "/api/eth_addresses/1"
      };
      if (Object.keys(this.firstPartOfProject).includes("investType")) {
        requestToIssue.investType = this.firstPartOfProject.investType;
      }
      if (Object.keys(this.firstPartOfProject).includes("isVoiting")) {
        requestToIssue.isVoiting = this.firstPartOfProject.isVoiting;
      }
      if (Object.keys(this.secondPartOfProject).includes("priceRedeem")) {
        requestToIssue.priceRedeem = this.secondPartOfProject.priceRedeem;
        requestToIssue.dateClose = this.secondPartOfProject.dateClose;
      }
      try {
        const resp = await this.setRequestToIssue(requestToIssue);
        let params = {};
        let uploadImage = this.thirdPartOfProject.image ? await this.sendFile(this.thirdPartOfProject.image) : this.thirdPartOfProject.oldImage;
        if (uploadImage) {
          params.mainImage = `/api/uploaded_files/${uploadImage.id}`;
        }
        let iconImage = this.thirdPartOfProject.iconImage ? await this.sendFile(this.thirdPartOfProject.iconImage) : this.thirdPartOfProject.oldIconImage;
        if (iconImage) {
          params.icon = `/api/uploaded_files/${iconImage.id}`;
        }
        params.additionalFile = await this.sendDocs();
        await this.putRequestToIssue({
          id: resp.id,
          requestToIssue: params
        });
        this.showSuccess(this.$t("toasts.success.emissionSend"));
        this.$router.push({ name: "my projects" });
      }
      catch (error) {
        this.showError(this.$t("toasts.error.emissionSend") + `: ${error.response.data.message}`);
      }
      finally {
        this.loading = false;
      }
    },

    async editForm() {
      this.loading = true;
      let params = {
        user: `/api/users/${this.$auth.user().id}`,
        tokenNameEng: this.firstPartOfProject.projectNameEng,
        tokenMnemonicEng: this.firstPartOfProject.projectAbbrNameEng,
        descriptionCapitalRus: this.firstPartOfProject.projectDescriptionRus,
        descriptionCapitalEng: this.firstPartOfProject.projectDescriptionEng,
        tokenCount: this.firstPartOfProject.tokensCount,
        company: this.firstPartOfProject.company,
        projectType: this.firstPartOfProject.projectType,
        cryptoActiveType: this.firstPartOfProject.cryptoActiveType,
        dateStart: this.secondPartOfProject.dateStart,
        dateEnd: this.secondPartOfProject.dateEnd,
        descriptionToken: "-",
        minCount: this.secondPartOfProject.minCount,
        section: this.secondPartOfProject.section,
        priceBase: this.secondPartOfProject.priceBase,
        tokenQuote: this.secondPartOfProject.tokenQuote,
        quote: this.secondPartOfProject.quote,
        expectedProfit: this.secondPartOfProject.expectedProfit,
        minCap: this.secondPartOfProject.minCap,
        maxCap: this.secondPartOfProject.maxCap,
        logicType: "asset",
        isForCapitall: true,
        ethAddress: !_.isEmpty(this.ethAddresses) ? `/api/eth_addresses/${this.ethAddresses[0].id}` : "/api/eth_addresses/1",
        additionalFile: await this.sendDocs()
      };
      if (Object.keys(this.firstPartOfProject).includes("investType")) {
        params.investType = this.firstPartOfProject.investType;
      }
      if (Object.keys(this.firstPartOfProject).includes("isVoiting")) {
        params.isVoiting = this.firstPartOfProject.isVoiting;
      }
      if (Object.keys(this.secondPartOfProject).includes("priceRedeem")) {
        params.priceRedeem = this.secondPartOfProject.priceRedeem;
        params.dateClose = this.secondPartOfProject.dateClose;
      }
      if (this.thirdPartOfProject.image !== null) {
        const upload = await this.sendFile(this.thirdPartOfProject.image);
        params.mainImage = `/api/uploaded_files/${upload.id}`;
      }
      if (this.thirdPartOfProject.iconImage !== null) {
        const upload = await this.sendFile(this.thirdPartOfProject.iconImage);
        params.icon = `/api/uploaded_files/${upload.id}`;
      }
      try {
        const resp = await this.editRequestToIssue([this.id, params]);
        resp.additionalFile = params.additionalFile;
        this.requestsToIssue[this.index] = resp;
        this.showSuccess(this.$t("toasts.success.emissionEdit"));
        this.$router.push({ name: "my projects" });
      }
      catch (error) {
        this.showError(this.$t("toasts.error.emissionEdit") + `: ${error.response.data.message}`);
      }
      finally {
        this.loading = false;
      }
    },

    formatDate(date) {
      return new Date(date).toISOString();
    },

    async sendFile(file) {
      let upload = null;
      try {
        upload = await this.uploadFile(file);
      }
      catch (e) {
        this.showError(this.$t("toasts.error.emissionFile"));
      }
      return upload;
    },

    async sendDocs() {
      let ret = [];
      for (const doc of this.thirdPartOfProject.fileList) {
        if (doc.id) {
          await this.patchFile({ id: doc.id, body: { embeddedCode: doc.embeddedCode } });
          ret.push(`/api/uploaded_files/${doc.id}`);
          continue;
        }

        const upload = await this.sendFile(doc);
        if (upload === null) {
          return [];
        }
        ret.push(`/api/uploaded_files/${upload.id}`);
      }
      return ret;
    },

    showSuccess(message) {
      this.$toast.success(message);
    },

    showError(message) {
      this.$toast.error(message);
    }
  }

};
</script>

<style>

</style>