<template>
  <span
    ref="tooltip"
    class="tooltip-content"
  >
    <span class="tooltip-text">?</span>
    <!-- <AuIcon
      icon="question"
      class="tooltip-icon"
    /> -->
  </span>
</template>

<script>
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";

export default {
  name: "AuTultip",
  props: {
    description: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      tooltip: null
    };
  },

  computed: {
    theme() {
      return this.$store.getters.isLightTheme ? "light" : "dark";
    }
  },

  watch: {
    theme(newTheme) {
      if (this.tippyInstance) {
        this.tippyInstance.setProps({ theme: newTheme });
      }
    }
  },

  mounted() {
    this.tooltip = this.$refs.tooltip;
    this.initTippy();
  },
  beforeUnmount() {
    if (this.tippyInstance) {
      this.tippyInstance.destroy();
    }
  },

  methods: {
    initTippy() {
      this.tippyInstance = tippy(this.tooltip, {
        content: this.description,
        trigger: "mouseenter click",
        arrow: true,
        theme: this.theme
      });
    }
  }
};
</script>

<style lang="scss">
.tooltip-content {
  position: absolute;
  margin-left: 3px;
}

.tooltip-icon {
  display: flex;
  width: 14px;
  cursor: pointer;
}
.tooltip-text {
  display: flex;
  font-size: 11px;
  cursor: pointer;
  color: #888;
  margin-top: -3px;
}

.tippy-box[data-theme~="light"] {
  padding: 2px;
  background-color: #1c2434;
  color: #fff;
  border-radius: 6px;
  font-size: 10px !important;
  font-family: "Open Sans", sans-serif;
}

.tippy-box[data-theme~="light"][data-placement^="top"] > .tippy-arrow:before {
  border-top-color: #1c2434;
}
.tippy-box[data-theme~="light"][data-placement^="bottom"]
  > .tippy-arrow:before {
  border-bottom-color: #1c2434;
}

.tippy-box[data-theme~="dark"] {
  padding: 2px;
  background-color: #fff;
  color: #1c2434;
  border-radius: 6px;
  font-size: 10px !important;
  font-family: "Open Sans", sans-serif;
}

.tippy-box[data-theme~="dark"][data-placement^="top"] > .tippy-arrow:before {
  border-top-color: #fff;
}
.tippy-box[data-theme~="dark"][data-placement^="bottom"]
  > .tippy-arrow:before {
  border-bottom-color: #fff;
}

.tippy-content {
  font-size: 12px;
}
</style>
