<template>
  <div class="is3 card">
    <div class="form-header">
      <span class="title">{{ $t('projects.form.thirdStep.label') }}</span>
      <AuButton
        width="auto"
        bordered
        @click="goBack"
      >
        {{ $t('projects.form.cancelButton') }}
      </AuButton>
    </div>
    <div class="is-label">
      {{ $t('projects.form.thirdStep.projectImageLabel') }}
    </div>
    <div class="is-indent">
      <AuUpload
        :src="src"
        :button="$t('projects.form.thirdStep.projectChooseImageButton')"
        width="100%"
        @change="data => ({src, image} = data)"
      />
    </div>
    <div class="is-label">
      {{ $t('projects.form.thirdStep.projectIconLabel') }}
    </div>
    <div class="is-indent">
      <AuUpload
        :src="iconSrc"
        :button="$t('projects.form.thirdStep.projectChooseImageButton')"
        width="100%"
        @change="data => { iconSrc = data.src; iconImage = data.image }"
      />
    </div>
    <div class="is-label">
      {{ $t('projects.form.thirdStep.projectDocsLabel') }}
    </div>
    <div class="is-indent">
      <AuUpload
        :files="fileList"
        :button="$t('projects.form.thirdStep.projectAddDocsButton')"
        mode="multiple"
        width="100%"
        embedded
        @added="f => fileList.push(f)"
        @deleted="index => fileList.splice(index, 1)"
        @embeddedChange="embeddedChange"
      />
    </div>
    <ProgressLoader
      v-if="loading"
      mode="indeterminate"
      color="#5AC396"
      size="150px"
    />
    <div
      v-else
      class="d-flex"
    >
      <AuButton
        class="mr-2"
        width="125px"
        bordered
        center
        @click="prevStep"
      >
        {{ $t('projects.form.backButton') }}
      </AuButton>

      <AuButton
        bordered
        center
        width="100%"
        type="primary"
        @click="send"
      >
        <span class="bnt-text">
          {{ $t('projects.form.sendButton') }}
        </span>
      </AuButton>
    </div>
    <div
      v-if="showError"
      class="error"
    >
      {{ error }}
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import ProgressLoader from "@/loaders/progress-bar";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ProjectFormThirdStep",
  components: {
    ProgressLoader
  },
  props: {
    id: {
      type: String,
      default: "new"
    },
    loading: {
      type: Boolean,
      default: false
    },
    thirdPartOfProjectInfo: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      src: null,
      image: null,
      oldImage: null,
      iconSrc: null,
      iconImage: null,
      oldIconImage: null,
      error: null,
      showError: false,
      fileList: []
    };
  },

  computed: {
    ...mapGetters({
      requestsToIssue: "requestsToIssue"
    })
  },

  async mounted() {
    if (_.isEmpty(this.requestsToIssue)) {
      await this.getrequestsToIssue();
    }
    if (this.thirdPartOfProjectInfo !== null) {
      this.src = this.thirdPartOfProjectInfo.src;
      this.image = this.thirdPartOfProjectInfo.image;
      this.iconSrc = this.thirdPartOfProjectInfo.iconSrc;
      this.iconImage = this.thirdPartOfProjectInfo.iconImage;
      this.fileList = this.thirdPartOfProjectInfo.fileList || [];
    }
    if (this.id !== "new" && !this.thirdPartOfProjectInfo) {
      this.setFields(this.requestsToIssue.find(item => item.id == this.id));
    }
  },

  methods: {
    ...mapActions({
      getUploadedFile: "files/getUploadedFile",
      getrequestsToIssue: "getRequestsToIssue"
    }),

    prevStep() {
      this.$emit("thirdPartOfProjectInfo", {
        src: this.src,
        image: this.image,
        oldImage: this.oldImage,
        iconSrc: this.iconSrc,
        iconImage: this.iconImage,
        oldIconImage: this.oldIconImage,
        fileList: this.fileList
      });
      this.$emit("prevStep");
    },

    goBack() {
      this.$router.push({ name: "my projects" });
    },

    embeddedChange(index, embedded) {
      this.fileList[index].embeddedCode = embedded;
      if (embedded) {
        this.fileList[index].isVideoEmbedded = true;
      }
      else {
        this.fileList[index].isVideoEmbedded = false;
      }
    },

    send() {
      if (!this.image && !this.oldImage) {
        this.error = this.$t("projects.form.error");
        this.showError = true;
        return;
      }
      if (this.image instanceof File && this.image.size > 2 * 1024 * 1024) {
        this.error = this.$t("projects.form.thirdStep.errorBigImage");
        this.showError = true;
        return;
      }
      this.$emit("thirdPartOfProjectInfo", {
        src: this.src,
        image: this.image,
        oldImage: this.oldImage,
        iconSrc: this.iconSrc,
        iconImage: this.iconImage,
        oldIconImage: this.oldIconImage,
        fileList: this.fileList
      });
      this.$emit("send");
    },

    async setFields(data) {
      this.image = null;
      this.fileList = [];

      if (!_.isNil(data?.mainImage)) {
        const id = data.mainImage.slice(data.mainImage.lastIndexOf("/") + 1);
        const file = await this.getUploadedFile(id);
        this.oldImage = file;
        this.src = file?.name;
      }
      if (!_.isNil(data?.icon)) {
        const id = data.icon.slice(data.icon.lastIndexOf("/") + 1);
        const file = await this.getUploadedFile(id);
        this.oldIconImage = file;
        this.iconSrc = file?.name;
      }
      if (data?.additionalFile?.length > 0) {
        for (let index = 0; index < data.additionalFile.length; index++) {
          const id = data.additionalFile[index].slice(data.additionalFile[index].lastIndexOf("/") + 1);
          const file = await this.getUploadedFile(id);
          if (file) {
            file.name = file.title;
            this.fileList.push(file);
          }
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.form-header {
  display: flex;
  justify-content: space-between;
  .title {
    font-weight: 500;
    font-size: 20px;
  }
}
.is3{
  font-weight: 400;
  font-size: 14px;
  padding: 20px;
}
.is-back{
  border: 1px dashed var(--color-80);
  border-radius: 15px;
  width: 180px;
  height: 44px;
  margin-right: 15px;
  &:hover {
   cursor: pointer;
  }
}
.is-send{
  border: 1px solid var(--color-80);
  border-radius: 12px;
  background: #289566;
  height: 44px;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
}
.is-label{
  margin-bottom: 15px;
}
.is-indent{
  margin-bottom: 35px;
  gap: 10px;
}
.error {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  color: red;
}
</style>